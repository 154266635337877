import { useState, useRef,useEffect } from 'react'
import 'leaflet-draw/dist/leaflet.draw.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDropDownAlert,
  setLocation,
  setLocationType,
} from '../AlertingSlice/AlertingSlice'
import { DrawComponent } from '../../DrawComponent/DrawComponent'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import { Polygon } from 'react-leaflet'
import { RootState } from '../../../store'
import { ErrorTimeouts } from '../../../constants/ErrorTimeouts'

let finalJoinedArray = ''

const AlertDraw = () => {
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const rectangleCoordinates = [
    [Number(defaultLocation.latitude) + 1.45, Number(defaultLocation.longitude) - 1.85], // topLeft
    [Number(defaultLocation.latitude) + 1.45, Number(defaultLocation.longitude) + 1.85], // topRight
    [Number(defaultLocation.latitude) - 1.45, Number(defaultLocation.longitude) + 1.85], // bottomRight
    [Number(defaultLocation.latitude) - 1.45, Number(defaultLocation.longitude) - 1.85]  // bottomLeft
  ];
  // <Polygon positions={rectangleCoordinates} />
  
  const selectedSideNav = 'Create an Alert'
  const drawControlRef = useRef<any | null>(null)
  const drawnPolylineRef = useRef<any | null>(null)
  const alert = ['Create an Alert']
  const [drawn, setDrawn] = useState(false)
  const dispatch = useDispatch()
  const errorStatus = useErrorStatus()
  useEffect(() => {
    errorStatus.addMessage("Please use the DropDown to begin Alerting evaluation", 200, "success", false, ErrorTimeouts.ALERTING) 
  }, []);
  const clearDrawnPolyline = () => {
    setDrawn(false)
    if (drawControlRef.current) {
      const drawControl = drawControlRef.current
      drawControl.leafletElement._toolbars.edit._modes.draw.handler.disable()
      drawControl.leafletElement._toolbars.edit._modes.remove.handler.enable()
      drawControl.leafletElement._toolbars.edit._modes.remove.removeAllLayers()
    }
    dispatch(setLocation(''))
  }
  const _onCreate = (e: any) => {
    drawnPolylineRef.current = e.layer
    if (e.layerType == 'marker') {
      const latLngs = e.layer.getLatLng()
      const formattedArray = []
      formattedArray[0] = latLngs.lng
      formattedArray[1] = latLngs.lat
      const result = JSON.stringify(formattedArray)
      finalJoinedArray = result
      dispatch(setLocation(''))
      dispatch(setLocationType(''))
      dispatch(setLocation(finalJoinedArray))
      dispatch(setLocationType('Point'))
      dispatch(setDropDownAlert(true))
    } else if (e.layerType === 'polygon') {
      const latLngs = e.layer.getLatLngs()
      latLngs.forEach((polygon: any[], index: number) => {
        const formattedArray = polygon.map((latLng) => [latLng.lng, latLng.lat])
        let formattedPolygon = JSON.stringify(formattedArray)
        if (finalJoinedArray.length > 0) {
          finalJoinedArray = finalJoinedArray.slice(
            0,
            finalJoinedArray.length - 1
          )
          finalJoinedArray += ', '
          formattedPolygon = formattedPolygon.slice(1, formattedPolygon.length)
        }
        finalJoinedArray += `[${formattedPolygon}]`
      })
      dispatch(setLocation(''))
      dispatch(setLocationType(''))
      dispatch(setLocation(finalJoinedArray))
      dispatch(setLocationType('Polygon'))
      dispatch(setDropDownAlert(true))
    } else if (e.layerType == 'polyline') {
      const latLngs = e.layer.getLatLngs()
      const formattedPolyline = latLngs.map(
        (latLng: { lat: any; lng: any }) => [latLng.lng, latLng.lat]
      )
      let result = JSON.stringify(formattedPolyline)

      if (finalJoinedArray.length > 0) {
        finalJoinedArray = finalJoinedArray.slice(
          0,
          finalJoinedArray.length - 1
        )
        finalJoinedArray += ', '
        result = result.slice(1, result.length)
      }
      finalJoinedArray += result

      dispatch(setLocation(''))
      dispatch(setLocationType(''))
      dispatch(setLocation(finalJoinedArray))
      dispatch(setLocationType('LineString'))
      dispatch(setDropDownAlert(true))
    }
    setDrawn(true)
  }
  const _onDelete = (e: any) => {
    clearDrawnPolyline()
    finalJoinedArray = ''
  }
  return (
    <>
      {alert.includes(selectedSideNav) && (<>
        <DrawComponent selectedSideNav={selectedSideNav} />
        <Polygon
        color="black"
        fill={true}
        fillOpacity={0.1}
        weight={Number(3)}
        positions={[rectangleCoordinates]}
      ></Polygon>
        </>
      )}
    </>
  )
}
export default AlertDraw
