/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { WMSTileLayer } from 'react-leaflet'
import { useEffect, useState } from 'react'
import { getWMSTile } from '../../services/WMSWeatherService'
import Toast, { toastPosition, toastType } from '../Toast/Toast'
import { WMSURL } from '../../environment/apis.config'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const defaultOpacity = 0.35
interface ILegendProps {
  legendUrl: string
  dark?:boolean
  param?: string
}

const Legend = (props: ILegendProps) => {
  let  url = props.legendUrl;
  let isTurb = props.param? "WAFS-Turbulence":""
  if (props.dark === true) {
    url = props.legendUrl + '&style=dark'
  }
  else {
    url = props.legendUrl
  }
  
  return (<>
    <img
      src={url}
      alt="Legend"
      style={{
        zIndex: '801',
        position: 'absolute',
        top: '3rem',
        right: '2rem',
      }}
    />
    { isTurb &&
      <div className="absolute top-32 right-8 z-[801] bg-white p-2">
        <p>
          0-10 NONE<br />
          10-20 LGT<br />
          40-70 SEV<br />
          70+ EXTRM<br />
        </p>
      </div>
    }
  </>
  
  )
}

const getWMSTileWithErrors = async (layerId: string) => {
  try {
    const data = await getWMSTile(layerId)

    if (typeof data === 'string') {
      // getWMSTile returns a string if there was an error
      throw new Error('Network error')
    }
    return data
  } catch (error) {
    if (error instanceof Error) {
      // Add additional information if needed
      // eslint-disable-next-line prettier/prettier
      error = error.message
    }
    throw error
  }
}

interface WMSLayerProps {
  opacity?: number
  legendUrl?: string
}

export function WMSVisibility(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Visibility_V360`
  const [legendUrl, setLegendUrl] = useState("")
  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Visibility')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])

  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && (
        <WMSTileLayer url={url} zIndex={800} opacity={opacity}></WMSTileLayer>
      )}
      <Legend legendUrl={legendUrl} dark={darkMode}/>
    </>
  )
}

export function WMSTemperature(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Temperature_V360`
  const [legendUrl, setLegendUrl] = useState("")
  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Temperature')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {

      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && (<div id="wmstilelayercontainer"><WMSTileLayer url={url} zIndex={800} opacity={opacity}/></div>)}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMS80MWinds(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const [legendUrl, setLegendUrl] = useState('')

  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('Winds Aloft')
        setUrl( uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (error) {
        setError(`Error: ${error}`)
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSSurfaceWinds(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Wind_V360`
  const [legendUrl, setLegendUrl] = useState('')
  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Surface Winds')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSCeiling(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Ceiling_V360`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Ceiling (US)')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSWindGust(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Wind_Gust_V360`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Wind Gust')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSSurfaceWindsCA(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Wind_V360`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Surface Winds (CA)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSTemperatureCA(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Temperature_V360`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Temperature (CA)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSCeilingCA(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=LAMP-Ceiling`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Ceiling (CA)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSVisibilityCA(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=LAMP-Visibility`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Visibility (CA)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSWindGustCA(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Wind_Gust_V360`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Wind Gust (CA)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMS80MWindsCA(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=Wind_80_V360`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('80M Wind (CA)')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSSurfaceWindsBR(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=CW100SP1-Wind`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Surface Winds (BR)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSTemperatureBR(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=CW100SP1-Temperature`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Temperature (BR)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSCeilingBR(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=MABR-Ceiling`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Ceiling (BR)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSVisibilityBR(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=CW100SP1-Visibility`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Visibility (BR)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSWindGustBR(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=CW100SP1-Wind_Gusts`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('Wind Gust (BR)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMS80MWindsBR(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=CW100SP1-Wind_80`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('80M Wind (BR)')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSSurfaceWindsUK(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity

  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('Surface Winds (Western Europe)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMS80MWindsUK(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const [legendUrl, setLegendUrl] = useState<string>('')

  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('80M Wind (Western Europe)')
        console.log(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (err) {
        setError(`Error: ${err}`)
        console.log(err)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSTemperatureUK(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState<string>('')
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('Temperature (Western Europe)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (err) {
        setError(`Error: ${err}`)
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSVisibilityUK(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity

  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState<string>('')
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('Visibility (Western Europe)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (err) {
        setError(`Error: ${err}`)
        console.log(err)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSCeilingUK(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity

  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState<string>('')
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('Ceiling (Western Europe)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (err) {
        setError(`Error: ${err}`)
        console.log(err)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WMSWindGustUK(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity

  const [url, setUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState<string>('')
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend } = await getWMSTileWithErrors('Wind Gust (Western Europe)')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        setLegendUrl(uriLegend)
      } catch (err) {
        setError(`Error: ${err}`)
        console.log(err)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}

export function WAFsIcing(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=WAFS-Icing`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('WAFS-Icing')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} />
    </>
  )
}
export function WAFsTurbulence(props: WMSLayerProps) {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const opacity = props.opacity !== undefined ? props.opacity : defaultOpacity
  const legendUri =
    props.legendUrl !== undefined
      ? props.legendUrl
      : `${WMSURL}/WMS?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=WAFS-Turbulence`
  const [url, setUrl] = useState<string | null>(null)
  const [legendUrl, setLegendUrl] = useState("")
  const [error, setError] = useState<string | null>(null)
  let darkStyle = ""
  
  useEffect(() => {
    darkStyle = darkMode ? "&style=dark" : ""
  },[darkMode])

  useEffect(() => {
    ;(async () => {
      try {
        const { uri, uriLegend: legend } = await getWMSTileWithErrors('WAFS-Turbulence')
        setUrl(uri + '&TILEZOOM={z}&TILECOL={x}&TILEROW={y}')
        if (legend !== "" ){
          setLegendUrl(legend)
        }
        else {
          setLegendUrl(legendUri)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [url])
  return (
    <>
      {error && (
        <Toast
          toastPosition={toastPosition.topRight}
          toastMessage={error}
          toastType={toastType.error}
        />
      )}
      {url && <WMSTileLayer url={url} zIndex={800} opacity={opacity} />}
      <Legend legendUrl={legendUrl} dark={darkMode} param={"WAFS-Turbulence"}/>
    </>
  )
}
