import { booleanPointInPolygon } from "@turf/boolean-point-in-polygon";
import { polygon, point } from "@turf/helpers";

export interface bboxProductDetailsInterface {
    bounds : any[],
    products : Set<string>,
    region : string
}
const boundingBoxes : bboxProductDetailsInterface[] = [
    {
        bounds :[
            [-134.5, 20],
            [-134.5, 56],
            [-60, 56],
            [-60, 20],
            [-134.5, 20]
          ]
       ,
        products : new Set(['location-menu', 'add-location', "alerts-menu", "alerts-create", "decision-products-menu", 'decision-missioncast', 'decision-routecast', 'radar-menu', 
            'observations-mrms-radar', 'observations-myradar','observations-lightning', 'observations-menu', 'surface-observations-menu', 'camera-network', 'vertical-profile',
            'area-weather-menu', 'weather-menu', 'forecast-weather-menu', 'aviation-menu', 'aviation-hazards','aviation-pirep','aviation-airmet','aviation-sigmet', 'aviation-metar','aviation-taf',
            'aviation-wafs-icing','aviation-wafs-turbulence','forecaster-menu', 'dark-mode-menu', 'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 
             'aviation-winds-80m', 'aviation-wind-gust', 'forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',]),
        region : "US"
    },
    {
        bounds :  [
            [-138, 55],   
            [-138, 70],   
            [-55, 70],    
            [-55, 55],   
            [-138, 55]  
        ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'radar-menu', 'observations-menu', 'surface-observations-menu', 
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "Canada"
    },
    {
        bounds : [
            [-10.9, 36.39],
            [-10.9, 57.79],
            [10, 57.79],
            [10, 36.39],
            [-10.9, 36.39]
          ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'decision-routecast','radar-menu', 'observations-myradar', 'observations-menu', 'surface-observations-menu', 'camera-network',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-winds-80m', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "W-Europe"
    },
    {
        bounds : [
            [10, 43.5],
            [10, 63.6],
            [30.79, 63.6],
            [30.79, 43.5],
            [10, 43.5]
          ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'decision-routecast','radar-menu', 'observations-myradar','observations-menu', 'surface-observations-menu', 'camera-network',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-winds-80m', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "C-Europe",
    },
    {
        bounds : [
            [-10.9, 36.39],
            [-10.9, 57.79],
            [10, 57.79],
            [10, 36.39],
            [-10.9, 36.39]
          ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'decision-routecast','radar-menu', 'observations-myradar', 'observations-menu', 'surface-observations-menu', 'camera-network',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-winds-80m', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "W-Europe"
    },
    {
        bounds : [
            [10, 43.5],
            [10, 63.6],
            [30.79, 63.6],
            [30.79, 43.5],
            [10, 43.5]
          ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'decision-routecast','radar-menu', 'observations-myradar','observations-menu', 'surface-observations-menu', 'camera-network',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-winds-80m', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "C-Europe"
    },
    {
        bounds : [
            [34, 20],
            [34, 33.89],
            [57.1, 33.89],
            [57.1, 20],
            [34, 20]
          ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'radar-menu', 'observations-menu', 'surface-observations-menu',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-winds-80m', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "M-East"
    },
    {
        bounds : [
            [-47.40, -24],
            [-47.40, -22.8],
            [-45.51, -22.8],
            [-45.51, -24],
            [-47.40, -24]
          ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast', 'radar-menu', 'observations-menu', 'surface-observations-menu',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds','forecast-aviation-winds-80m','forecast-aviation-wind-gust',
            'aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surface-winds', 'aviation-winds-80m', 'aviation-wind-gust','weather-menu','area-weather-menu',
        ]),
        region : "Sao Paulo"
    }
]

const tilesIdSet = new Set(['aviation-ceiling', 'aviation-visibility', 'aviation-temperature', 'aviation-surfacewinds',  'aviation-winds-80m', 'aviation-wind-gust'])
const tilesIdRegionMap = {
    "US" : {
        'aviation-ceiling' : 'aviation-ceiling',
        'aviation-visibility' : 'aviation-visibility',
        'aviation-temperature' : 'aviation-temperature',
        'aviation-surface-winds' : 'aviation-surface-winds',
        'aviation-winds-80m' : 'aviation-winds-80m',
        'aviation-wind-gust' : 'aviation-wind-gust'
    },
    "Canada" : {
        'aviation-ceiling' : 'aviation-ceiling-ca',
        'aviation-visibility' : 'aviation-visibility-ca',
        'aviation-temperature' : 'aviation-temperature-ca',
        'aviation-surface-winds' : 'aviation-surface-winds-ca',
        'aviation-wind-gust' : 'aviation-windgust-ca'
    },
    "W-Europe":{
        'aviation-ceiling' : 'aviation-ceiling-uk',
        'aviation-visibility' : 'aviation-visibility-uk',
        'aviation-temperature' : 'aviation-temperature-uk',
        'aviation-surface-winds' : 'aviation-surface-winds-uk',
        'aviation-winds-80m' : 'aviation-winds-80m-uk',
        'aviation-wind-gust' : 'aviation-wind-gust-uk'
    },
    "C-Europe" : {
        'aviation-ceiling' : 'aviation-ceiling-ce',
        'aviation-visibility' : 'aviation-visibility-ce',
        'aviation-temperature' : 'aviation-temperature-ce',
        'aviation-surface-winds' : 'aviation-surface-winds-ce',
        'aviation-winds-80m' : 'aviation-winds-80m-ce',
        'aviation-wind-gust' : 'aviation-wind-gust-ce'
    },
    "M-East":{
        'aviation-ceiling' : 'aviation-ceiling-middle-east',
        'aviation-visibility' : 'aviation-visibility-middle-east',
        'aviation-temperature' : 'aviation-temperature-middle-east',
        'aviation-surface-winds' : 'aviation-surface-winds-middle-east',
        'aviation-winds-80m' : 'aviation-winds-80m-middle-east',
        'aviation-wind-gust' : 'aviation-wind-gust-middle-east'
    },
    "Sao Paulo":{
        'aviation-ceiling' : 'aviation-ceiling-br',
        'aviation-visibility' : 'aviation-visibility-br',
        'aviation-temperature' : 'aviation-temperature-br',
        'aviation-surface-winds' : 'aviation-surface-winds-br',
        'aviation-winds-80m' : 'aviation-winds-80m-br',
        'aviation-wind-gust' : 'aviation-windgust-br'
    },
    "Default" : {
        'aviation-ceiling' : 'aviation-ceiling',
        'aviation-visibility' : 'aviation-visibility',
        'aviation-temperature' : 'aviation-temperature',
        'aviation-surface-winds' : 'aviation-surface-winds',
        'aviation-winds-80m' : 'aviation-winds-80m',
        'aviation-wind-gust' : 'aviation-wind-gust'
    },
}

interface Location {
    longitude: number;
    latitude: number;
    [key: string]: any; 
}
  
function getRegionBasedServices(currentLocation:Location) : object{
    var region = {
        bounds : [      ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast','radar-menu', 'observations-xweather-radar', 'observations-menu', 'surface-observations-menu',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds',
            'forecast-aviation-winds-80m','forecast-aviation-wind-gust', 'weather-menu','area-weather-menu'
        ]),
        region : "Default"
    }
    var region = {
        bounds : [      ],
        products : new Set(['location-menu', 'add-location', "decision-products-menu", 'decision-missioncast','radar-menu', 'observations-xweather-radar', 'observations-menu', 'surface-observations-menu',
            'aviation-menu','aviation-pirep', 'aviation-metar','aviation-taf','forecast-aviation-ceiling', 'forecast-aviation-visibility','forecast-aviation-temperature', 'forecast-aviation-surface-winds',
            'forecast-aviation-winds-80m','forecast-aviation-wind-gust', 'weather-menu','area-weather-menu'
        ]),
        region : "Default"
    }
    var pt = point([-76.14, 43.04])
    if(currentLocation.longitude && currentLocation.latitude){        
        pt = point([currentLocation.longitude, currentLocation.latitude]);
    }
    for(let boxes of boundingBoxes){
        let currrPolygon = polygon([boxes.bounds])
        if(booleanPointInPolygon(pt, currrPolygon)) return boxes
    }
    return region
} 

export {getRegionBasedServices, tilesIdSet, tilesIdRegionMap};
