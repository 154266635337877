import { useState,useEffect} from 'react';
import { useDispatch } from 'react-redux'
import edit from '../../assets/edit.svg'
import history from '../../assets/history.svg'
import cancel from '../../assets/cancel.svg'
import save from '../../assets/save.svg'
import {fetchThresholds,updateThresholdValue,deleteThresholdValue } from './customThresholdsSlice';
import MainNavMenu from '../../menus/MainNavMenu/MainNavMenu';
import messagealert from '../../assets/message-alert-square.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { getUser } from '../../utils/auth.util';

interface Threshold {
  wind80M:any,visibility:any,temperatureLowRange:any,temperatureHighRange:any,surfaceWindGust:any,surfaceWind:any,
  precipitation:any,ceilingHeight: {
    previousMarginalRisk: string;
    currentMarginalRisk: string;
    currentHighRisk: string;
    previousHighRisk:string;
  };
}


export const CustomThresholds = () => {
  const dispatch = useDispatch<any>()
  const itemsPerPage = 2; 
  const [currentPage, setCurrentPage] = useState(1);
  const [userThresholds, setUserThresholds] = useState<Threshold[]>([]); 
  const { isAuthenticated } = useAuth0();

  const [error, setError] = useState('');
  const [editT, setEditT] = useState(false);
  const [tempThreshold, setTempThreshold] = useState(0);
  const [tempThreshold1, setTempThreshold1] = useState(0);
  const [editingVariable, setEditingVariable] = useState('');

  const [buttonClicked, setButtonClicked] = useState(false);
  const threshold = userThresholds[0];
  const updateParameterValue = async (
    parameterName: string,
    riskType: string,
    customValue: any
  ) => {
    try {
      const updatedThreshold: Threshold = { ...threshold };
        if(parameterName === 'ceilingHeight'){
        updatedThreshold[parameterName][riskType] = ['<',String(customValue),'ft'];
      }else if(parameterName === 'precipitation'){
        updatedThreshold[parameterName][riskType] = ['>=',String(customValue),'%'];
      }else if(parameterName === 'visibility'){
        updatedThreshold[parameterName][riskType] = ['<',String(customValue),'sm'];
      }else if(parameterName === 'surfaceWind' || parameterName === 'wind80M' || parameterName === 'surfaceWindGust'){
        updatedThreshold[parameterName][riskType] = ['>=',String(customValue),'kts'];
      }
      else if(parameterName === 'temperatureHighRange'){
        updatedThreshold[parameterName][riskType] = ['>=',String(customValue),'F'];
      }else{
        updatedThreshold[parameterName][riskType] = ['<=',String(customValue),'F'];
      }
  
      const updatedRequestBody = {
        ...updatedThreshold,
      };
   
      const responseAction = await dispatch(updateThresholdValue({ parameterName, updatedThreshold }));

    if (updateThresholdValue.fulfilled.match(responseAction)) {
      setEditT(false);
      const action = await dispatch(fetchThresholds());
  
      if (fetchThresholds.fulfilled.match(action)) {
        const responseData = action.payload;
          setUserThresholds([responseData]);
      } else {
        console.error('Error fetching thresholds');
      }
    } else {
      setError("Error updating thresholds");
    }
    } catch (error) {
      setError('error');
    }
  };
  
  useEffect(() => {
    const fetchDataAndThresholds = async () => {
      const action = await dispatch(fetchThresholds());
      if (fetchThresholds.fulfilled.match(action)) {
        const responseData = action.payload;
          setUserThresholds([responseData]);
      } else {
        console.error('Error fetching thresholds');
      }
    };
  
    if (isAuthenticated && getUser()) {
      fetchDataAndThresholds();
    }

  }, [buttonClicked, isAuthenticated, getUser()]); 

             
  if (userThresholds.length === 0) {
    return <div>Loading...</div>;
  }

  const clickedOnEdit = (a:any,b:any,c:any)=>{
    switch(c){
      case '0':
        setTempThreshold(threshold[a][b][1])
      break
      case '1':
        setTempThreshold1(threshold[a][b][1])
      break
    }    
  }

  const deleteThresholds = async(parameter: string)=>{
    const action = await dispatch(deleteThresholdValue(parameter));
  
    if (deleteThresholdValue.fulfilled.match(action)) {
      const action = await dispatch(fetchThresholds());
  
      if (fetchThresholds.fulfilled.match(action)) {
        const responseData = action.payload;
          setUserThresholds([responseData]);
      } else {
        console.error('Error fetching thresholds');
      }
    } else {
      console.error('Error fetching thresholds');
    }
  }
   const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
   const itemsToDisplay = userThresholds.slice(startIndex, endIndex);
   const totalItems = 8;
   const paginatedData = Object.entries(threshold).slice(
    startIndex,
    endIndex
  );

const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (newPage: number) => {
 
    setCurrentPage(newPage);
  };
  const formatKey =(key: any) =>{
    if (typeof key === 'string') {
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
    } else {  
      return key.toString(); 
    }
  }
   
  return (
    <div className="max-h-screen flex flex-col  w-full overflow-y-clip bg-white">
      <MainNavMenu/>
    <div className="flex-1  mx-auto p-10 ">
      <ul className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-1 md:gap-8 sm:mt-12 md:mt-2">
     {paginatedData.map(([key, value],index) => {
      if(key=="updatedAt" || key=="createdAt" ){
        return(<></>)
      }
      return ( 
        
        <li key={key} className="">
        <div className=" flex flex-col  bg-gray-100 rounded-2xl">
         <div className="flex pr-8 pb-2 pt-2 rounded-tl-2xl rounded-br-2xl text-lg pl-8 ">
            <h1 className="flex-1 font-semibold">{formatKey(key) === 'Wind80 M' ? '80m Winds' : formatKey(key)}</h1>
            <button
              className="flex- 3 py-2 px-4 bg-gray-100 border-solid border-gray-300 border rounded-md hover:bg-gray-200 hover:cursor-pointer flex items-center"
              title="Revert to prevoious thresholds"
              onClick={() => {
                deleteThresholds(key)
              }}
            >
              <img
                src={history}
                alt="Edit"
                className="h-6"
                style={{ height: '18px' }}
              />
            </button>
          </div>
          <div className="grid grid-cols-4 grid-flow-row pb-8">
            <div className="bg-gray-100 p-4"> </div>
            <div className="bg-gray-100 pt-4 sm:pr-12 font-medium">Previous Value</div>
            <div className="bg-gray-100 pr-4 pl-4 pt-4 font-medium">Custom</div>
            <div className="bg-gray-100 pr-4 pt-4 pb-4 font-medium">
              {' '}
              Actions
            </div>
            <div className="p-2 mt-2 font-medium mx-4">
              {' '}
              Marginal Risk
            </div>
            <div className="p-2 mt-2">
            {value.previousMarginalRisk[0] +
            value.previousMarginalRisk[1] +
            value.previousMarginalRisk[2]}
            </div>
            <div className="p-2"> 
            {editT && editingVariable === (key+'Prev') ? ( 
        <input
          type="text"
          inputMode="numeric"
          value={tempThreshold}
          className="w-20 sm:w-12 pt-2"
          onChange={(e) =>{
            const updatedValue = Number(e.target.value);
            setTempThreshold(updatedValue);
            setButtonClicked(true)
          }
          }
        />
      ) : (
        <div className="p-2 ">
          {value.currentMarginalRisk[0] +
    value.currentMarginalRisk[1] +
    value.currentMarginalRisk[2]}
        </div>
      )}
              {editT && editingVariable === key+'Prev' && <>
                <button title="Save threshold"
              className="py-1 px-2 md:py-2 md:px-4 bg-green-500 rounded-md border-solid border-green-500 border hover:bg-green-600 hover:cursor-pointer ml-2"
              onClick={() => {
                updateParameterValue(key, 'currentMarginalRisk', tempThreshold);
              }}
            >
              <img
                src={save}
                alt="Edit"
                className="h-4 invert"
              />
            </button>
            <button title="Discard changes"
              className="py-1 px-2 md:py-2 md:px-4 bg-red-500 rounded-md border-solid border-red-500 border hover:bg-red-600 hover:cursor-pointer ml-2"
              onClick={() => {
                  setEditT(false)
              }}
            >
              <img
                src={cancel}
                alt="Edit"
                className="h-4 invert"
              />
            </button></> }
            </div>
        
            <div className="p-2">
              <button title="Edit Threshold"
                className="py-2 px-4 bg-gray-100 rounded-md border-solid border-gray-300 border hover:bg-gray-200 hover:cursor-pointer flex items-center"
                onClick={() => {
                 setEditT(true)
                 setEditingVariable(key+'Prev')
                 clickedOnEdit(key, 'currentMarginalRisk','0')
                }}
              >
                <img
                  src={edit}
                  alt="Edit"
                  className="h-4"
                />
              </button>
            </div>
            <div className="p-2 mt-2 font-medium mx-4">
              High Risk
            </div>
            <div className="p-2 mt-2">
            {value.previousHighRisk[0] +
    value.previousHighRisk[1] +
    value.previousHighRisk[2]}
            </div>
            <div className="p-2">

            {editT && editingVariable===key+'Current' ? ( 
        <input
          type="text"
          inputMode="numeric"
          value={tempThreshold1}
          className="w-20 sm:w-12"
          onChange={(e) =>{
            const updatedValue = Number(e.target.value);
            setTempThreshold1(updatedValue);
            setButtonClicked(true)
          }
          }
        />
      ) : (
        <div className="p-2">
          {value.currentHighRisk[0] +
    value.currentHighRisk[1] +
    value.currentHighRisk[2]}
        </div>
      )}
              {editT && editingVariable === key+'Current' &&  <>
                <button title="Save threshold"
              className="py-1 px-2 md:py-2 md:px-4 bg-green-500 rounded-md border-solid border-green-500 border hover:bg-green-600 hover:cursor-pointer ml-2"
              onClick={() => {
                updateParameterValue(key, 'currentHighRisk', tempThreshold1);
              }}
            >
              <img
                src={save}
                alt="Edit"
                className="h-4 invert"
              />
            </button>
            <button title="Discard changes"
              className="py-1 px-2 md:py-2 md:px-4 bg-red-500 rounded-md border-solid border-red-600 border hover:bg-red-600 hover:cursor-pointer ml-2"
              onClick={() => {
                setEditT(false)
              }}
            >
              <img
                src={cancel}
                alt="Edit"
                className="h-4 invert contrast-150"
              />
            </button></> }
            </div>
            <div className="p-2">
              <button title="Edit Threshold"
                className="py-2 px-4 bg-gray-100 rounded-md hover:bg-gray-200 border-solid border-gray-300 border hover:cursor-pointer flex items-center"
                onClick={() => {
                 setEditT(true)
                 setEditingVariable(key+'Current')
                 clickedOnEdit(key, 'currentHighRisk','1')
                }}
              >
                <img
                  src={edit}
                  alt="Edit"
                  className="h-4"
                />
              </button>
            </div>
          </div>
        </div>
      </li>
      )})}
      </ul>
      <div className="flex items-center pl-9 pt-2 mt-12 md:mt-4 h-6">
          <img src={messagealert}  className="w-10 h-10" /> 
          <p className="text-sm font-normal text-gray-600">Custom thresholds will affect color coding for MissionCast</p>
        </div>
      <div className='justify-end mt-12 md:mt-2 md:ml-[900px] flex ml-auto'>
      <nav>
  <ul className="inline-flex">
    <li>
      <button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-600 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        Previous
      </button>
    </li>
    {Array.from({ length: totalPages }, (_, index) => (
      <li key={index}>
        <button
          onClick={() => handlePageChange(index + 1)}
          className={`flex items-center justify-center px-3 h-8 leading-tight ${
            currentPage === index + 1
              ? 'text-back-600 bg-blue-50 hover:bg-black-100 hover:text-black-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
              : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
          }`}
        >
          {index + 1}
        </button>
      </li>
    ))}
    <li>
      <button
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-600 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        Next
      </button>
    </li>
  </ul>
</nav>
      </div>
    </div>
  </div>
  );
};
  
