const questions = [
    {
        "header": "General FAQs",
        "subquestions": [
            {
                "title": "Isn't the information that operators get from TV stations, weather apps, and the National Weather Service good enough?",
                "answer": `While traditional weather sources like TV stations, apps, and the National Weather Service provide valuable information, they are often focused on broad regional forecasts and may not capture the localized, low-altitude weather conditions that are critical for safe and efficient drone, air taxi, and low-altitude aviation operations.<br><br>TruWeather Solutions V360° platform is specifically designed to address this gap by collecting and crowd-sourcing micro-weather data from a variety of government, commercial, and non-traditional (IoT) sources in the exact locations where drones and low-altitude aircraft operate. This allows V360° to provide highly accurate, high-resolution weather intelligence tailored to the unique needs of the advanced air mobility industry.`
            },
            {
                "title": "How does V360° differ from other weather data platforms in the market?",
                "answer": `V360° is one of the first weather data platforms built from the ground up to cater to the specific requirements of the advanced air mobility industry, including drones, air taxis, and low-altitude aviation. Unlike traditional weather platforms, V360° focuses on collecting and analyzing micro-weather data at low altitudes (below 5,000 feet AGL), where most of these operations take place.<br><br>Additionally, V360° offers a comprehensive suite of features tailored to the needs of operators, dispatchers, schedulers, and airspace managers, including customizable weather alerts, route evaluation tools, sensor integration, and a user-friendly interface for weather decision-making.`
            },
            {
                "title": "What kind of weather data and forecasts does V360° provide?",
                "answer": `V360° provides high-resolution weather predictions, observations, and analytics for various parameters critical to low-altitude operations, such as cloud/ceiling, visibility, surface wind, low-altitude wind, lightning, and NWS warnings. These data and forecasts are available across the United States, Canada, Europe, the Middle East, and other regions, with more areas being added regularly.`
            },
            {
                "title": "Can V360° integrate with external weather sensors or data sources?",
                "answer": `Yes, one of the key strengths of V360° is its ability to integrate with a variety of novel weather instruments and data sources that are not commonly available, such as ground-based cameras, miniature weather stations, weather drones, and others. This sensor plug-and-play feature allows users to access a more comprehensive picture of actual weather conditions in their region of interest, reducing uncertainty and enabling better decision-making.`
            },
            {
                "title": "How can V360° help operators and airspace managers improve their operations?",
                "answer": `V360° is designed to empower operators, dispatchers, schedulers, and airspace managers with the most advanced weather decision tools available. The platform's features, such as customizable weather alerts, route evaluation tools, and real-time weather monitoring, can help optimize operations by providing timely and accurate information for mission planning, route optimization, and weather-related decision-making.`
            },
            {
                "title": "What kind of support and resources are available for V360° users?",
                "answer": `TruWeather Solutions offers a range of support and resources to ensure a seamless experience for V360° users. This includes a comprehensive tutorial library, user guides, and dedicated customer support channels for any questions or assistance needed. Additionally, we regularly update the platform with new features and enhancements based on customer feedback and industry requirements.`
            },
        ]
    },
    {
        "header": "Location and Current Conditions",
        "subquestions": [
            {
                "title": "How do I add a new location to the V360° platform?",
                "answer": `To add a new location, expand the "Location" toggle on the side panel, click on "Add New Location", select a point on the map, name the location according to your preference, and click "Submit." This will add the new location to your account.`
            },
            {
                "title": "How many locations can I add to my account?",
                "answer": `You can add up to three locations to your account. The default location is based on your billing address.`
            },
            {
                "title": "What is the V360° Best Estimate, and how is it calculated?",
                "answer": `The V360° Best Estimate is a weather report derived from a blend of real observational data (such as METAR, other weather observations, and gridded weather analysis algorithms) merged into a single report. This provides the most accurate estimate of current weather conditions relevant to your selected time and location.`
            },
            {
                "title": "What weather parameters are included in the Current Conditions report?",
                "answer": `The Current Conditions report displays various weather parameters, including temperature, K-index, visibility, ceiling, wind speed, wind gust, wind direction, cloud cover, altitude, and dewpoint temperature.`
            },
            {
                "title": "How often is the Current Conditions report updated?",
                "answer": `The Current Conditions report is updated in real-time, providing you with the latest weather information for your selected location.`
            },
            {
                "title": "Can I customize the weather parameters displayed in the Current Conditions report?",
                "answer": `Currently, the weather parameters displayed in the Current Conditions report are pre-defined. TruWeather Solutions is working on implementing customization options in future updates based on user feedback. However, a user can customize the weather parameters displayed in the Alerts and the Decision Products.`
            },
            {
                "title": "How does the V360° platform determine the accuracy of the Current Conditions report?",
                "answer": `The V360° platform leverages advanced data fusion algorithms to combine multiple sources of observational data, gridded weather analyses, and other proprietary algorithms to provide the most accurate representation of current weather conditions for the selected location.`
            },
        ]
    },
    {
        "header": "Alerts",
        "subquestions": [
            {
                "title": "What is the Alerting feature in V360°, and how does it work?",
                "answer": `The Alerting feature is a powerful, real-time weather alerting engine designed to provide ground point and airspace weather alerts customized to user-defined thresholds and distances for specific missions and operations. When the specified thresholds are exceeded or specific weather events occur, an automated email message is triggered and promptly sent out.`
            },
            {
                "title": "Is the Alerting feature included in all V360° subscriptions?",
                "answer": `No, the Alerting feature is currently only available as part of the premium V360° subscription.`
            },
            {
                "title": "What weather parameters can I set alerts for?",
                "answer": `You can set alerts for various weather parameters, including ceiling, visibility, surface winds and wind gusts, winds at 250 FT above ground level, temperature, precipitation, lightning, and NWS tornado and thunderstorm warnings.`
            },
            {
                "title": "How do I create a new weather alert?",
                "answer": `To create a new weather alert, expand the "Alerts" toggle, click on "Create an Alert," and follow the prompts. You'll need to provide an event name, start and end time, select the area or location for the alert, specify email addresses for notifications, and define the weather hazard thresholds.`
            },
            {
                "title": "Can I set alerts for a specific location or area?",
                "answer": `Yes, you can set alerts for a specific location or a defined area. For a specific location, you can also specify the diameter or radius of the alert area. For an area, you can use the map controls to draw a route or an area polygon.`
            },
            {
                "title": "How will I be notified when an alert is triggered?",
                "answer": `When an alert is triggered, an automated email message will be sent to the email addresses you specified during the alert setup process. Additionally, a colored alert icon will appear next to the parameter that triggered the alert under the "Alerts" toggle, and a colored region will be displayed on the map. You can click on these icons to view the alert details and edit the alert parameters if needed.`
            },
            {
                "title": "Can I view and manage active alerts within the V360° platform?",
                "answer": `Yes, the Events List under the "Alerts" toggle displays a list of all active events. Once an event is created and an alert is issued, you can click on the map area to find the "Change Alert Settings" option, where you can edit the event parameters. You can also access alert notifications by clicking on the triangle with an alert icon next to the account icon in the header.  Additionally, if you click the thin black border showing the alert area, you can see all the events associated with that area and their parameters.`
            },
        ]
    },
    {
        "header": "Decision Products",
        "subheader": [
            {
                "header": "MissionCast",
                "subquestions": [
                    {
                        "title": "What is MissionCast, and how does it work?",
                        "answer": `MissionCast is a Go/No-Go mission-specific stoplight chart that reflects custom thresholds for your designated takeoff and landing location, specific airframe, and mission. It displays variables that have a direct effect on flight efficiency and safety, such as current weather, chance of precipitation, visibility, surface winds and wind gusts, winds at 250FT above ground level, temperature, and dew point. Each variable is optimized for accuracy using a blend of microscale and regional models.`
                    },
                    {
                        "title": "How is the stoplight color coding determined in MissionCast?",
                        "answer": `The stoplight colors (red, yellow, green) in MissionCast are determined based on the custom thresholds you set for each weather variable. You can customize these thresholds by going to the "Custom Thresholds" page within the V360° platform.`
                    },
                    {
                        "title": "What is the forecast range and resolution for MissionCast?",
                        "answer": `MissionCast provides a short-range forecast for the next 24 hours, with hourly updates. It also offers an extended range forecast from 25 to 144 hours, displayed in daily increments with 3-hour breaks.`
                    },
                    {
                        "title": "Is MissionCast available in all V360° subscription plans?",
                        "answer": `Yes, the MissionCast feature is included in the standard V360° subscription plan.`
                    },
                    {
                        "title": "How can I interpret the MissionCast stoplight chart for my mission?",
                        "answer": `The MissionCast stoplight chart provides a quick visual representation of the weather conditions for your mission. A green light indicates favorable conditions based on your custom thresholds, yellow suggests caution or marginal conditions, and red signifies conditions that exceed your defined thresholds, which may impact flight safety or efficiency.  By reviewing the stoplight colors across the different weather variables, you can quickly assess the overall risk and make an informed go/no-go decision for your mission.`
                    },
                ]
            },
            {
                "header": "RouteCast",
                "subquestions": [
                    {
                        "title": "What is RouteCast and how does it work?",
                        "answer": `RouteCast is a route evaluation tool that allows users to input route waypoints and set wind or ceiling/visibility thresholds that impact safety and mission accomplishment. It then provides custom route data with color-coded risk levels (red, yellow, green) based on the predicted weather conditions and the user-defined thresholds. RouteCast pulls the best predictive weather data based on the user's subscription and provides resolutions as precise as 1000 meters where available.`
                    },
                    {
                        "title": "How do I define the route for RouteCast?",
                        "answer": `You can define the route by using the map legends/tools on the bottom left-hand corner of the V360° platform. You can draw the route as a polyline or as a polygon. For a polygon, ensure that the route is closed.`
                    },
                    {
                        "title": "How are the thresholds for winds and ceiling/visibility set in RouteCast?",
                        "answer": `For wind thresholds, the risk levels are determined based on whether the predicted wind speed is greater than the user-provided threshold value. For ceiling and visibility thresholds, the risk levels are determined based on whether the predicted values are less than the user-provided threshold values.`
                    },
                    {
                        "title": "Is RouteCast included in all V360° subscription plans?",
                        "answer": `No, the RouteCast feature is currently only available as part of the premium V360° subscription plan. It is not included in the standard plan.`
                    },
                    {
                        "title": "Can I combine RouteCast with other features like MissionCast or Alerting?",
                        "answer": `Yes, the V360° platform allows you to utilize multiple features together. For example, you can use RouteCast in conjunction with MissionCast or Alerting to gain a comprehensive view of weather conditions along your planned route and receive alerts if specific thresholds are exceeded.`
                    },
                ]
            },
        ]
    },
    {
        "header": "Observations and Radar NowCAST",
        "subheader": [
            {
                "header": "Radar",
                "subquestions": [
                    {
                        "title": "What is the Current Radar feature?",
                        "answer": `The Current Radar feature provides a time-lapse mosaic display of reflectivity for the past two hours. This allows you to see where precipitation has fallen and the prevailing direction of showers or storms. It's important to note that there may be times when weather radar depicts precipitation not reaching the ground, or it may not display precipitation that is occurring. It is recommended to validate precipitation by checking surface weather observations or the local camera network.`
                    },
                    {
                        "title": "How can the Current Radar feature assist in operational decisions?",
                        "answer": `By showing the location and movement of precipitation over the past two hours, the Current Radar feature can help operators make informed decisions about their operations. It provides a visual representation of recent precipitation patterns, which can be used in conjunction with other weather data sources and forecasts.`
                    },
                    {
                        "title": "What is the MyRadar™feature?",
                        "answer": `MyRadar™is a visualized version of the MyRadar™application that provides predicted weather movement for the next hour. This feature assists in making real-time operational decisions by providing short-term forecasts of precipitation and storm movement.`
                    },
                    {
                        "title": "How does the MyRadar™feature differ from the Current Radar feature?",
                        "answer": `While the Current Radar feature shows a time-lapse of past precipitation, the MyRadar™feature focuses on providing predictions of weather movement for the upcoming hour. This allows operators to anticipate and prepare for potential weather impacts on their operations in the near future.`
                    },
                    {
                        "title": "How often are the Radar NOWCAST features updated?",
                        "answer": `Both the Current Radar and MyRadar™features are updated regularly to ensure you have access to the most recent radar data and predictions. The update frequency may vary based on the availability of new radar data and weather model outputs.`
                    },
                    {
                        "title": "Can the Radar NOWCAST features be used in combination with other V360° features?",
                        "answer": `Yes, the Radar NOWCAST features can be used alongside other V360° features, such as Current Area Weather, Camera Network, and Alerting, to provide a comprehensive view of current and forecasted weather conditions. This integrated approach helps operators make informed decisions based on multiple data sources.`
                    },
                ]
            },
            {
                "header": "Surface Observations",
                "subquestions": [
                    {
                        "title": "What are Surface Observations in the V360° platform?",
                        "answer": `Surface Observations are real-time weather data pulled directly from public weather instrumentation and sensor data sources. These include METAR reports from weather observation stations, Mesonet sites, and weather sensors deployed by TruWeather Solutions or its clients.`
                    },
                    {
                        "title": "What kind of data is included in Surface Observations?",
                        "answer": `Surface Observations provide various weather parameters such as temperature, wind speed and direction, visibility, cloud cover, precipitation, and other relevant meteorological data measured at or near the Earth's surface.`
                    },
                    {
                        "title": "How are Surface Observations displayed in the V360° platform?",
                        "answer": `Each Surface Observation is displayed on the map with a color-coded icon (red, yellow, or green) to denote specific adverse weather conditions that could impact drone flight operations.`
                    },
                    {
                        "title": "What do the different color codes for Surface Observations represent?",
                        "answer": `The color codes for Surface Observations represent the following:  -   Red: Indicates adverse weather conditions that may significantly impact drone flight operations.  -   Yellow: Indicates marginal weather conditions that could potentially impact operations.  -   Green: Indicates favorable weather conditions for drone flight operations.`
                    },
                    {
                        "title": "How often are Surface Observations updated?",
                        "answer": `Surface Observations are updated in real-time or near real-time, depending on the update frequency of the respective data sources. This ensures that you have access to the most current weather information from ground-based sensors and observation stations.`
                    },
                    {
                        "title": "Can I customize the thresholds for the color-coding of Surface Observations?",
                        "answer": `No, you cannot currently customize the thresholds that determine the color-coding of Surface Observations. The color codes (red, yellow, green) are based on predefined industry criteria followed by the UAV industry to indicate adverse, marginal, or favorable weather conditions for drone flight operations. TruWeather Solutions has set these thresholds according to industry standards to ensure consistency and safety.`
                    },
                    {
                        "title": "How are Surface Observations different from other weather data sources in the V360° platform?",
                        "answer": `Surface Observations provide ground-truth data directly from weather sensors and observation stations, complementing other weather data sources such as forecasts, radar, and camera networks. This combination of data sources provides a comprehensive view of current and forecasted weather conditions.`
                    },
                ]
            },
            {
                "header": "Current Area Weather",
                "subquestions": [
                    {
                        "title": "What is the Current Area Weather feature?",
                        "answer": `The Current Area Weather feature provides weather tile visualizations that fuse the best datasets from observational and gridded predictions for various weather parameters such as cloud ceiling heights, visibility, temperature, surface winds, and winds at 80 meters above ground level (AGL).`
                    },
                    {
                        "title": "What weather parameters are included in the Current Area Weather visualizations?",
                        "answer": `The Current Area Weather visualizations cover cloud ceiling heights, visibility, temperature, surface wind speed and direction, and wind gusts and direction at 80 meters AGL.`
                    },
                    {
                        "title": "How accurate are the Current Area Weather visualizations?",
                        "answer": `The Current Area Weather visualizations represent the best estimates by combining observational data and gridded weather predictions. However, it is recommended to cross-check with real surface observations and the Camera Network for the most up-to-date and accurate information.`
                    },
                    {
                        "title": "Which regions are covered by the Current Area Weather feature?",
                        "answer": `Currently, the Current Area Weather feature provides visualizations for the United States, Canada, the United Kingdom, Central Europe, and the Middle East. Support for New Zealand and South America is coming soon.`
                    },
                    {
                        "title": "How often are the Current Area Weather visualizations updated?",
                        "answer": `The Current Area Weather visualizations are updated regularly to provide you with the most recent information. The update frequency may vary based on the availability of new observational data and weather model outputs.`
                    },
                ]
            },
            {
                "header": "Camera Network",
                "subquestions": [
                    {
                        "title": "What is the Camera Network feature, and how does it work?",
                        "answer": `The Camera Network feature utilizes a network of both private and public cameras (CCTV) to perform analytics and provide metadata and observation metrics for road conditions and visibility. By analyzing data from these cameras, the V360° platform can offer insights into real-time conditions in various locations.`
                    },
                    {
                        "title": "What kind of information can I access through the Camera Network?",
                        "answer": `Through the Camera Network feature, you can access observational data and metrics related to road conditions (such as wet or dry surfaces) and visibility levels. This information is derived from analyzing the feeds from the network of cameras.`
                    },
                    {
                        "title": "How is the data from the Camera Network integrated into the V360° platform?",
                        "answer": `The observational data and metrics obtained from the Camera Network are integrated into the V360° platform, providing you with an additional layer of real-time information. This data can be combined with other weather data sources and forecasts available in the platform, enabling you to make more informed decisions based on comprehensive environmental conditions.`
                    },
                ]
            },
            {
                "header": "Aviation Weather",
                "subquestions": [
                    {
                        "title": "What is the Aviation Weather feature in V360°?",
                        "answer": `The Aviation Weather feature is a one-stop shop that provides access to various official aviation weather products, including PIREPs (Pilot Weather Reports), AIRMETs (Airmen's Meteorological Information), SIGMETs (Significant Meteorological Information), METARs (Aviation Routine Weather Reports), and TAFs (Terminal Aerodrome Forecasts). These products can provide additional decision insights from official government weather data sources.`
                    },
                    {
                        "title": "How can the Aviation Weather Products assist in flight operations?",
                        "answer": `The Aviation Weather Products offer valuable information for flight planning and decision-making. PIREPs provide real-time weather observations from pilots, AIRMETs and SIGMETs alert pilots about significant weather hazards, METARs provide current weather conditions at airports, and TAFs provide forecasts for specific airports. These products can help operators assess potential weather impacts on their operations.`
                    },
                    {
                        "title": "How are the Aviation Weather products displayed in the V360° platform?",
                        "answer": `The Aviation Weather products are integrated into the V360° platform's map interface, allowing users to visualize the location and extent of the reported weather phenomena. Additionally, the platform may provide textual information and details associated with each product.`
                    },
                    {
                        "title": "How often are the Aviation Weather products updated?",
                        "answer": `The update frequency of the Aviation Weather products varies depending on the specific product and the issuing authority. PIREPs, AIRMETs, and SIGMETs are typically updated as new information becomes available, while METARs and TAFs follow scheduled update cycles (e.g., hourly for METARs and every six hours for TAFs).`
                    },
                    {
                        "title": "Are the Aviation Weather products available for all regions covered by V360°?",
                        "answer": `The availability of Aviation Weather products may vary based on the region and the coverage provided by the respective aviation authorities. TruWeather Solutions aims to integrate these products for all regions where they are available and relevant for flight operations.`
                    },
                    {
                        "title": "How does the Aviation Weather feature in V360° differ from other aviation weather sources?",
                        "answer": `The Aviation Weather feature in V360° consolidates various official aviation weather products from government sources into a single platform, providing a comprehensive view of relevant weather information for flight operations. Unlike standalone aviation weather sources, V360° integrates these products with other weather data, forecasts, and decision-making tools, enabling operators to make informed decisions based on a holistic view of the weather conditions.`
                    },
                ]
            },
            {
                "header": "Maps",
                "subquestions": [
                    {
                        "title": "What types of maps are available in the V360° platform?",
                        "answer": `The V360° platform offers two different map rendering options: Road maps and Satellite maps. These map types provide different perspectives and visualizations to suit various operational needs.`
                    },
                    {
                        "title": "What is the purpose of the Road map view?",
                        "answer": `The Road map view displays a traditional map interface with labeled roads, highways, and other transportation infrastructure. This view is particularly useful for ground-based operations, route planning, and identifying locations relative to recognizable landmarks and structures.`
                    },
                    {
                        "title": "What does the Satellite map view offer?",
                        "answer": `The Satellite map view provides high-resolution satellite imagery of the Earth's surface. This view can be beneficial for assessing terrain features, identifying potential obstacles or hazards, and visualizing areas without detailed road infrastructure data.`
                    },
                    {
                        "title": "Can I switch between the Road map and Satellite map views?",
                        "answer": `Yes, the V360° platform allows you to easily switch between the Road map and Satellite map views. This flexibility enables you to choose the map perspective that best suits your current operational needs or preferences.`
                    },
                    {
                        "title": "Can I customize the map view or settings?",
                        "answer": `The V360° platform offers various customization options for the map view, such as adjusting the zoom level, enabling or disabling certain map layers, and adjusting the map. These customization options can help tailor the map display to your specific needs and preferences.`
                    },
                    {
                        "title": "How often are the map data and imagery updated?",
                        "answer": `The frequency of map data and imagery updates may vary depending on the source and the region. TruWeather Solutions aims to provide up-to-date map data and imagery to ensure accurate and reliable visualizations for users.`
                    },
                ]
            },
        ]
    },
    {
        "header": "Miscellaneous",
        "subheader": [
            {
                "header": "Your Profile",
                "subquestions": [
                    {
                        "title": "What information can I update on the `Your Profile` page?",
                        "answer": `On the "Your Profile" page, you can update your personal information, including your first name, last name, and mobile number.`
                        // "answer": `On the "Your Profile" page, you can update your personal information, including your first name, last name, and mobile number. Additionally, you can change your password by following the prompts in the "Change Password" section.`
                    },
                    {
                        "title": "Can I change my email address from the `Your Profile` page?",
                        "answer": `No, you cannot change your email address from the "Your Profile" page. Your email address is a unique identifier used by TruWeather Solutions, and you\'ll need to contact support to update it.`
                    },
                    {
                        "title": "What are the `Custom Units` settings?",
                        "answer": `The "Custom Units" section allows you to set your preferred units for temperature, wind speed, and precipitation rate. These settings will be applied throughout the V360° platform.`
                    },
                    {
                        "title": "Can I manage my subscription from the `Your Profile` page?",
                        "answer": `Yes, the "Your Profile" page displays information about your current subscription plan. From this page, you can cancel or resubscribe to a different plan. However, detailed information about subscription plans is available on our TruWeather Solutions website.`
                    },
                ]
            },
            {
                "header": "Weather Sensor Details",
                "subquestions": [
                    {
                        "title": "What is the purpose of the `Weather Sensor Details` page?",
                        "answer": `The "Weather Sensor Details" page allows authorized users to add or manage weather sensors by entering the device IDs or tokens. This ensures that the sensor data is integrated into the V360° platform\'s observation map.`
                    },
                    {
                        "title": "What sensor types are currently supported?",
                        "answer": `Currently, the V360° platform supports Barani, Tempest, IntelliSense, and other sensor types. To add other sensor types, contact TruWeather Solutions support at support@truweathersolutions.com.`
                    },
                ]
            },
            {
                "header": "Custom Thresholds",
                "subquestions": [
                    {
                        "title": "What are the `Custom Thresholds` settings?",
                        "answer": `The "Custom Thresholds" page allows you to customize the thresholds that affect the MissionCast feature. By adjusting these thresholds, you can tailor the MissionCast stoplight chart to your specific operational requirements and weather sensitivities.`
                    },
                    {
                        "title": "How do the `Custom Thresholds` impact the MissionCast feature?",
                        "answer": `The "Custom Thresholds" settings determine the color-coding (red, yellow, green) of the MissionCast stoplight chart. By adjusting the thresholds for various weather parameters, such as wind speed, ceiling, and visibility, you can define the conditions under which MissionCast will display favorable, marginal, or adverse conditions for your operations.`
                    },
                ]
            },
        ]
    },
];

export default questions;
