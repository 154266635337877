export const timeouts = {
  // timeouts are in milliseconds
  routecast: 5000,
  customProfile: 5000,
  locations: 4000,
  surfaceObs: 5000,
  sensorDetails: 5000,
  taf: 5000,
  sigmet: 5000,
  pirep: 5000,
  airmet: 5000,
  metar: 5000,
  cameraObs: 5000,
  missionCAST: 20000,
  verticalProfile: 10000,
}
export const pollingIntervals = {
    airmetRefresh: 10 * 60 * 1000, // 10 minutes
    sigmetRefresh: 10 * 60 * 1000, // 10 minutes
    metarRefresh: 60 * 1000, // 1 minute
    tafRefresh: 15 * 60 * 1000, // 15 minutes
    pirepRefresh: 2 * 60 * 1000, // 2 minutes
    alertRefresh: 60 * 1000,
  }
