import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getAccessToken } from '../../utils/auth.util'
import { TWS_API_URL } from '../../environment/apis.config'

const PricingTable = () => {
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    const fetchHtml = async () => {
      const token = await getAccessToken()
      window.location.href = `${TWS_API_URL}/payment?token=${token}`
    }

    if (isAuthenticated) {
      fetchHtml()
    }
  }, [isAuthenticated])

  return null
}

export default PricingTable
