import {
  Location,
  AddLocationProps,
  DeleteLocationProps,
} from '../../interfaces/Location'
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { AddNewLocation, DeleteLocation } from '../../api/LocationApiService'
import { User, LoginPayload, RegisterPayload } from '../../interfaces/user'
import {
  fetchUserDetails,
  Login,
  Register,
  UpdateContact,
} from '../../services/UserApiService'

const initialState: User = {
  firstname: null,
  lastname: null,
  email: null,
  company: null,
  primary: null,
  secondary: null,
  isAdmin: null,
  id: null,
  totalLocations: null,
  isAuthenticated: false,
  defaultLocation: {},
  locations: [],
  addNewLocation: false,
  error: '',
  status: '',
  reseller: { reseller: '' },
  radar: false,
  userDefLoc: '',
  customUnits: {
    precipitationRate: '',
    temperature: '',
    wind_speed: '',
  },
}

const fetchUser = createAsyncThunk('/user/details', async (): Promise<User> => {
  const response = await fetchUserDetails()
  return response
})

const updateContactDetails = createAsyncThunk(
  '/dashboardClientSettings',
  async ({
    userId,
    firstname,
    lastname,
    primary,
  }: {
    userId: string
    firstname: string
    lastname: string
    primary: string
  }): Promise<User> => {
    const response = (await UpdateContact(
      userId,
      firstname,
      lastname,
      primary
    )) as User
    return response
  }
)

const UserLogin = createAsyncThunk(
  '/user/login',
  async (payload: LoginPayload): Promise<User> => {
    const response = await Login(payload)
    return response
  }
)

const RegisterUser = createAsyncThunk(
  '/user/register',
  async (payload: RegisterPayload) => {
    if (!payload.address || payload.address.replace(/,/g, '').trim() === '') {
      payload.address = ''
    }
    const response = await Register(payload)
    return response
  }
)

const addNewUserLocation = createAsyncThunk(
  '/user/location/addNewLocation',
  async (payload: AddLocationProps): Promise<Location[]> => {
    try {
      const response = await AddNewLocation(payload)
      return response
    } catch (error) {
      console.log('THUNK ERROR: ', error)
      throw error
    }
  }
)

const deleteUserLocation = createAsyncThunk(
  '/user/location/deleteLocation',
  async (payload: DeleteLocationProps): Promise<Location> => {
    const response = await DeleteLocation(payload)
    return response
  }
)

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDefaultLocation: (state, action) => {
      state.defaultLocation = { ...action.payload }
    },
    showAddLocationPopup(state) {
      state.addNewLocation = true
    },
    hideAddLocationPopup(state) {
      state.addNewLocation = false
    },
    showRadar(state) {
      state.radar = true
    },
    hideRadar(state) {
      state.radar = false
    },
    userDefLocation(state, action) {
      state.userDefLoc = { ...action.payload }
    },
    updateCustomUnits: (state, action) => {
      state.customUnits = { ...action.payload }
    },
    userLogoutAction: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        // return action.payload
      })
      .addCase('setDefaultLocation', (state, action) => {
        state.defaultLocation = { ...action.payload }
      })
      .addCase(UserLogin.fulfilled, (state, action) => {
        if (action.payload && action.payload.locations) {
          action.asyncDispatch({
            type: 'setDefaultLocation',
            payload: action.payload.locations[0],
          })
        }
        return action.payload
      })
      .addCase(UserLogin.rejected, (state) => {
        state.isAuthenticated = false
      })
      .addCase(addNewUserLocation.pending, (state) => {
        state.status = 'loading' // Change state.locations.status to state.status
      })
      .addCase(addNewUserLocation.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.locations = [...state.locations, action.payload] // Use spread operator
        action.asyncDispatch({
          type: 'setDefaultLocation',
          payload: action.payload,
        })
        action.asyncDispatch({
          type: 'deselectMenuItem',
          payload: {
            id: 'add-location',
          },
        })
      })
      .addCase(addNewUserLocation.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message // Change state.locations.error to state.error
      })
      .addCase(deleteUserLocation.fulfilled, (state, action) => {
        state.status = 'succeeded'
        let locations = current(state).locations
        locations = locations.filter(
          (loc: Location) => loc.id !== action.payload[0].id
        )
        state.locations = [...locations]
        action.asyncDispatch({
          type: 'setDefaultLocation',
          payload: state.locations[0],
        })
      })
      .addCase(deleteUserLocation.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateContactDetails.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        // return action.payload
      })
  },
})

export {
  fetchUser,
  UserLogin,
  RegisterUser,
  addNewUserLocation,
  deleteUserLocation,
  updateContactDetails,
}

export const {
  showAddLocationPopup,
  hideAddLocationPopup,
  setDefaultLocation,
  showRadar,
  hideRadar,
  userDefLocation,
  updateCustomUnits,
  userLogoutAction,
} = UserSlice.actions
export default UserSlice.reducer
