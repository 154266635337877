import { useEffect, useState } from 'react';
import 'flowbite';
import { useDispatch, useSelector } from 'react-redux';
import { callNetworkAdapter, callSpecificAlert, hideAlertSETTING, hideAlertSETTINGEdit, setAlertingNotification, showAlertSETTING, showAlertSETTINGEdit, sideNavAlerts } from '../AlertingSlice/AlertingSlice';
import useAlertSetting from '../useAlertSetting';
import { elements } from '../../../interfaces/alertingelements'
import { selectedItems } from '../../../menus/SideNavigation/SideNavigationSlice';
import { MenuLabels } from '../../../constants/MenuLabels';


type Alert = {
    id: number;
    formattedDate: string;
    formattedTime: string;
};
let allEvents: {
    key: number,
    endTime: string,
    summary: string,

}

const AlertNotificationBox = (props) => {
    const hazardType_label_map = {
        TEMPERATURE: 'Temperature',
        WINDS_80M: '250FT Wind Speed',
        WINDS_RTMA: 'Surface Wind Speed',
        VISIBILITY: 'Visibility',
        WINDGUSTS_RTMA: 'Surface Wind Gust',
        CEILINGS: 'Ceiling',
        MRMS_PRECIP: 'Precipitation',
        //LIGHTNING_ALERT: 'Lightning',
        TOR_WARNING_NWS: 'NWS Alerts',
        SVR_WARNING_NWS: 'NWS Alerts',
      }
    const { dismissAlert, addOnlyNotificationAlert, removeAlertNotification,removingNotificationAlert} = useAlertSetting();
    let displayingAlerts = props.properties;
    // (propertyElements.forEach((element) => {
    //     if(!removeAlertNotification?.includes(element?.value?.properties?.id)){
    //         displayingAlerts.unshift(element.value.properties);
    //     }
    // }));
    const dispatch = useDispatch();
    const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
    // addOnlyNotificationAlert(displayingAlerts[currentAlertIndex]?.properties.id);
    const handlePrevAlert = () => {
        setCurrentAlertIndex((prevIndex) =>
            prevIndex === 0 ? displayingAlerts.length - 1 : prevIndex - 1
        );
    };

    const handleNextAlert = () => {
        setCurrentAlertIndex((prevIndex) => (prevIndex + 1) % displayingAlerts.length);
    };
    const handleCloseAlert = () => {
        props.onClose();
        //dispatch(hideAlertSETTING());
        //dispatch(hideAlertSETTINGEdit());
    };
    const [showSettings, setShowSettings] = useState(false);
    let shouldDisplay='';
    const handleShowSettingsClick = () => {
        setShowSettings(!showSettings);
        if (!showSettings) {
            dispatch(showAlertSETTING());
            dispatch(showAlertSETTINGEdit());
            openAlertSetting(displayingAlerts[currentAlertIndex].properties)
            closeAlertSetting();
        }
        else if (showSettings) {
            dispatch(hideAlertSETTING());
            dispatch(hideAlertSETTINGEdit());
        }
    };
    const { selectedProperties, openAlertSetting, closeAlertSetting } = useAlertSetting();

    const handleDismissAlert = () => {
        if(displayingAlerts?.length==1){
            dispatch(selectedItems(MenuLabels.ALERTS_MENU));
            dispatch(callNetworkAdapter(false))
        }
        dismissAlert(displayingAlerts[currentAlertIndex]?.properties.id);
        removingNotificationAlert(displayingAlerts[currentAlertIndex]?.properties.id)
        setCurrentAlertIndex(0);
        dispatch(hideAlertSETTING());
        dispatch(hideAlertSETTINGEdit());
      };
    
    useEffect(() => {
        addOnlyNotificationAlert(displayingAlerts[currentAlertIndex].properties.id)
        dispatch(sideNavAlerts(hazardType_label_map[displayingAlerts[currentAlertIndex].properties.hazardType]))
    }, [currentAlertIndex])
    const alertBoxContent = (
        <>
            <div className="bg-gray-100 text-black p-2 flex items-center" style={{ borderRadius: '10px 10px 0px 0px' }}>
                <span className="font">Alert</span>
                <button
                    className="ml-auto bg-transparent text-black border-none cursor-pointer text-sm"
                    onClick={handleCloseAlert}
                >
                    x
                </button>
            </div>
            <hr />
            <div className="p-2 text-center">
                {displayingAlerts[currentAlertIndex]?.properties.validAt}
            </div>
            <div className="bg-yellow-700 text-black p-3 text-center text-sm h-32 overflow-y-scroll">
                {displayingAlerts[currentAlertIndex]?.properties.triggerText}
            </div>
            <div className="flex justify-between mt-4">
                <button
                    className="prev-arrow mt-0 bg-white hover:bg-gray-300 text-black font-semibold px-3 py-2 mx-1 my-5 rounded"
                    onClick={handlePrevAlert}
                >
                    {'<'}
                </button>
                <button
                    className="bg-green-500 text-white px-4 py-2 text-center mx-1 my-5 mt-4 rounded-md"
                    onClick={handleDismissAlert}
                >
                    Dismiss Alert
                </button>
                <button
                    className="next-arrow mt-0 bg-white hover:bg-gray-300 text-black font-semibold px-3 py-2 mx-1 my-5 rounded"
                    onClick={handleNextAlert}
                >
                    {'>'}
                </button>
            </div>
            <hr className="mt-2" />
            {/* <button
                className="text-black text-sm px-20 py-2 text-center mt-0 rounded-md flex mt-2 ml-3"
                onClick={handleShowSettingsClick}
            >

                {showSettings ? 'Hide Alert Settings' : 'Change Alert Settings'}

            </button> */}
            {

            }
            {elements.map((element) => {
                     if(displayingAlerts[currentAlertIndex]?.properties.hazardType === element.value ){
                         shouldDisplay=''
                        shouldDisplay+=(element.id)
                     }
                })}
            {
                (shouldDisplay !==  'Lightning' && shouldDisplay !==  'NWS Tornado Warning' &&  shouldDisplay !==  'NWS Severe Thunderstorm Warning') &&
                <div className="rounded-md p-2 mt-4">
                <div
                    className={`text-black text-sm px-2 py-1 text-center ml-4 mr-4 ${displayingAlerts[currentAlertIndex]?.properties.mag <= 30 ? 'bg-red-500' : 'bg-red-500'
                        }`}
                >
                    {shouldDisplay} threshold is {displayingAlerts[currentAlertIndex]?.properties.comparison} {" "} {parseFloat(displayingAlerts[currentAlertIndex]?.properties.eventThreshold).toFixed(2)} {displayingAlerts[currentAlertIndex]?.properties.thresholdUnits}
                </div>
                </div>
            }    
            
        </>
    );
    return (
        <div className="fixed bottom-60 left-55 right-60 top-11 w-80 h-96 border bg-white border-gray-300 rounded-md flex flex-col" style={{ borderRadius: '10px 10px 10px 10px', zIndex: 1000 }}>
            {alertBoxContent}
        </div>
    );
};
export default AlertNotificationBox;
