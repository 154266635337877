//npm module imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

//component imports
import { UserLogin } from '../UserSlice'
import Toast, { toastPosition, toastType } from '../../Toast/Toast'
import { RootState } from '../../../store'
import radar from '../../../assets/radar.gif'
import TWSLogo from '../../../assets/TruWeatherWhite.svg'
import {Footer} from '../../../Components/Footer/Footer'
import { ContactUsButton } from '../../ContactUs/ContactUs'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import { ErrorTimeouts } from '../../../constants/ErrorTimeouts'

export default function LoginPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const message = useSelector((state: RootState) => state.user.message)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  })
  const errorStatus = useErrorStatus()

  const onSubmit = async (data) => {
    try {
      const user = await dispatch(UserLogin({ ...data }))
      if (user.payload && user.payload.token && user.payload.id) {
        localStorage.setItem('token', user.payload.token)
        localStorage.setItem('login-event', 'login-'+Math.random())
        localStorage.setItem('userId', user.payload.id)
        navigate('/map')
      } else {
        errorStatus.addMessage("Something went wrong. Please try again!", 400, toastType.error, false, ErrorTimeouts.GENERAL)
      }
    } catch (error) {
      errorStatus.addMessage("Invalid email or password. Please try again!", 400, toastType.error, false, ErrorTimeouts.GENERAL)
      navigate('/')
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate('/map')
    }
  })

  return (
    <div className="flex flex-col justify-center items-center h-screen w-full">
    <div className="w-25 flex flex-row p-6">
      <div className="flex flex-col items-center">
          <img className="p-8 max-w-[512px]" src={radar}/>
          <img className="w-65 h-28" src={TWSLogo} />
        </div>
        <div className="flex flex-col justify-center content-center w-1/2 h-65 m-2">
          <div className="justify-center">
            <h2 className="text-center text-2xl font-bold mb-6 tracking-wider">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label
                className="block text-gray-700 text-md tracking-wide font-bold mb-2"
                htmlFor="email"
              >
                Email Address <span className="text-red-500">*</span>
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-6"
                id="email"
                type="email"
                placeholder="test@test.com"
                {...register('email', {
                  required: ' Email address is required',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: ' Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p className="text-red-500 before:content-['⚠']">
                  {errors.email.message}
                </p>
              )}

              <div className="mb-2">
                <label
                  className="block text-gray-700 text-md font-bold mb-2 tracking-wide"
                  htmlFor="password"
                >
                  Password <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="abcd@123"
                  {...register('password', {
                    required: ' Password is required',
                  })}
                />
                {errors.password && (
                  <p className="text-red-500 before:content-['⚠']">
                    {errors.password.message}
                  </p>
                )}
              </div>

              <div className="text-right text-blue-500 my-4 hover:underline tracking-wide">
                <Link to="/resetPassword">Forgot your password?</Link>
              </div>

              <div className="flex items-center justify-between">
                <button className="bg-green-500 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline tracking-wide">
                  Login
                </button>
              </div>

              <div className="text-right text-blue-500 my-4 hover:underline tracking-wide">
                <Link to="/signup">
                  Don&apos;t have an account? Create your account.
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
  <Footer/>
      <ContactUsButton></ContactUsButton>
    </div>
  )
}
