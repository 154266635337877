import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import indicator from '../../../assets/alert.svg'
import AlertNotificationBox from '../../../Components/Alerting/AlertingBox/AlertNotificationBox'
import { callNetworkAdapter, sideNavRefresh } from '../../../Components/Alerting/AlertingSlice/AlertingSlice'
import { selectedItems } from '../../SideNavigation/SideNavigationSlice'
import { MenuLabels } from '../../../constants/MenuLabels'
import { RootState } from '../../../store'

export default function AlertIndicator(props) {
  const dispatch=useDispatch();
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const alertnumber = props.alertnumber
  const alerts = props.alerts
  const dismissedAlerts = props.dismissedAlerts
  const handleButtonClick = () => {
    if (alertnumber === 0) {
      setShowToast(true)
    } 
    if(!isAlertBoxOpen){
      dispatch(selectedItems(MenuLabels.ALERTS_MENU));
      dispatch(sideNavRefresh())
      dispatch(callNetworkAdapter(true));
    }
    else{
      dispatch(selectedItems(MenuLabels.ALERTS_MENU));
      dispatch(callNetworkAdapter(false));
    }
    setIsAlertBoxOpen(!isAlertBoxOpen)
    }
  const handleCloseAlertBox = () => {
    setIsAlertBoxOpen(false)
  }
  useEffect(() => {
    if (showToast) {
      const toastTimeout = setTimeout(() => {
        setShowToast(false)
      }, 3000)
      return () => {
        clearTimeout(toastTimeout);
      };
    }
  }, [showToast])

  let badge = <></>

  if (typeof alertnumber !== 'undefined' && alertnumber > 0) {
    badge = (
      <div className="absolute bg-red-600 w-4 h-4 text-white rounded-full text-xs p-1 text-center z-10 flex items-center justify-center">
        {alertnumber}
      </div>
    )
  }
  const onDismissAlert = () => {}
  // const dismissedAlerts = []
  if (alertnumber && alertnumber > 0) {
    return (
      <div
        className="self-center"
        title={alertnumber + ' alerts'}
      >
        <button className="relative w-6 h-6 align-middle" onClick={handleButtonClick}>
          {badge}
          <img className="invert" src={indicator} alt="" style={{ filter: 'brightness(0) invert(1)' }}/>
        </button>
        {isAlertBoxOpen && (
          <AlertNotificationBox
            alertnumber={alertnumber}
            onDismissAlert={onDismissAlert}
            onClose={handleCloseAlertBox}
            dismissedAlerts={dismissedAlerts}
            properties={alerts[0]}
          />
        )}
      </div>
    )
  } else {
    return (
      <div
        className="self-center"
        title={`${alertnumber} alerts`}
      >
        <button className="relative w-6 h-6 align-middle" onClick={handleButtonClick}>
          {badge}
          <img className="invert" src={indicator} alt="" style={{ filter: 'brightness(0) invert(1)' }}/>
        </button>
        {showToast}
      </div>
    )
  }
}
