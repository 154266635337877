export const ErrorTimeouts = {
    MISSIONCAST: 15 * 1000,
    USER_PROFILE: 10 * 1000,
    CUSTOM_THRESHOLDS: 10 * 1000,
    ROUTECAST: 15 * 1000,
    VERTICAL_PROFILE: 10 * 1000,
    SENSORS: 10 * 1000,
    ADD_LOCATION: 10 * 1000,
    ALERTING: 10 * 1000,
    GENERAL: 10 * 1000
}