import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const firstLoginErrorMsg = "client requires organization membership, but user does not belong to any organization"

const PostRegistration = () => {
  const { error, logout } = useAuth0();

  useEffect(() => {
    if (error?.message === firstLoginErrorMsg) {
      // Show the Account creation message instead.
    } else {
      returnToLogin();
    }
  }, []);

  const returnToLogin = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
  }

  return (
    <div className="w-full max-w-md mx-auto p-4 bg-green-100 border border-green-300 rounded-lg shadow-md flex flex-col items-center text-center">
      <div className="w-10 h-10 flex items-center justify-center bg-green-500 text-white rounded-full font-bold mb-2">
        ✓
      </div>

      <div>
        <h3 className="text-green-800 font-semibold">Account created successfully!</h3>
        <p className="text-green-700 text-sm mt-1">
          Please login again to begin using v360Weather Workbook.

        </p>
      </div>

      <button
        onClick={() => returnToLogin()}
        className="mt-4 text-blue-600 font-semibold hover:underline"
      >
        Back to Login
      </button>
    </div>
  );
};

export default PostRegistration;