import { useEffect, useState, useRef, useCallback } from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import Toast, { toastPosition, toastType } from '../Toast/Toast'
import { toPng } from 'html-to-image'
import markerImg from '../../assets/vertical-profile_marker.png'
import L, { LatLngTuple } from 'leaflet'
import './VerticalProfile.css'
import skewtThumbnailImage from '../../assets/skewtThubnail.png'
import { timeouts } from '../../services/RequestTimeouts'
import useErrorStatus from '../../hooks/UseErrorStatus'
import { deselectMenuItemById } from '../../menus/SideNavigation/SideNavigationSlice'
import { getAccessToken } from '../../utils/auth.util'
import { VerticalProfileAPI } from '../../services/SurfaceObsAPI'
import { RootState } from '../../store'
import React from 'react';
import { ErrorTimeouts } from '../../constants/ErrorTimeouts'

interface Geometry {
  type: 'Point' | 'Polygon' | 'LineString';
  coordinates: [number, number];
}
interface VerticalProfileInterface {
  type: 'Feature';
  geometry: Geometry;
  properties: Record<string, any>;
}

const VerticalProfile = () => {
  const [verticalProfile, setWeather] = useState<Array<VerticalProfileInterface>>([])
  const [showVerticalProfile, setverticalProfileMarker] = useState(false)
  const location = useSelector((state) => state.user.defaultLocation)
  const elementRef = useRef<HTMLDivElement>(null)
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [imageURL, setImageURL] = useState<string>('')
  const [imagePos, setImagePos] = useState<LatLngTuple >([0, 0])
  const [timedOut, setTimedOut] = useState<boolean>(false)
  const controller = new AbortController()
  const errorStatus = useErrorStatus()
  const dispatch = useDispatch()
  const timeoutId = setTimeout(() => {
    controller.abort('API call timed out')
    clearTimeout(timeoutId)
    setTimedOut(true)
  }, timeouts.verticalProfile)
  const currentLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )

  const getData = async () => {
    try {   
      const data = await VerticalProfileAPI(currentLocation)
      if (data.message == 'Not Authorized.') {
        errorStatus.addMessage('User not authorized', 400, 'error', false, ErrorTimeouts.VERTICAL_PROFILE)
      } else if ( data?.features.length > 0) {
        errorStatus.addMessage(
          'Vertical profile data availabe in the current location',
          400,
          'success',
          false,
          ErrorTimeouts.VERTICAL_PROFILE
        )
        setWeather(data.features)
        setverticalProfileMarker(true)
      } else {
        errorStatus.addMessage(
          'No vertical profile data availabe in the current location',
          400,
          'error',
          false,
          ErrorTimeouts.VERTICAL_PROFILE
        )
        // deselect vertical profile menu
        dispatch(deselectMenuItemById('vertical-profile'))
      }
    } catch (error) {
      if (error.message === 'The user aborted a request.') {
        // set by abort controller
        dispatch(deselectMenuItemById('vertical-profile'))
        errorStatus.addMessage(
          'Vertical profile request timed out. Please try again.',
          400,
          'error',
          false,
          ErrorTimeouts.VERTICAL_PROFILE
        )
      } else {
        errorStatus.addMessage(error.message, 400, 'error', false,
          ErrorTimeouts.VERTICAL_PROFILE)
      }
    }
  }
  // Trigger when we load the page
  useEffect(() => {
    getData()
  }, [location])

  const color_maping = (key: string, val: any) => {
    const base_string = 'border border-black px-2 whitespace-nowrap text-center '
    let color_string = 'bg-slate-500'
    if (isNaN(val)) {
      return base_string + color_string
    }
    let low_temp
    let high_temp
    if (key == 'temp') {
      if (val >= 2) low_temp = 'green'
      else if (val < 2 && val >= -4) low_temp = 'yellow'
      else low_temp = 'red'

      if (val <= 32) high_temp = 'green'
      else if (val > 32 && val < 34) high_temp = 'yellow'
      else high_temp = 'red'

      if (low_temp === 'green' && high_temp === 'green') {
        color_string = 'bg-green-300'
      }
      if (low_temp === 'yellow' || high_temp === 'yellow') {
        color_string = 'bg-yellow-300'
      }
      if (low_temp === 'red' || high_temp === 'red') {
        color_string = 'bg-red-300'
      }
    } else if (key == 'wind') {
      if (val <= 8) color_string = 'bg-green-300'
      else if (val > 8 && val <= 14) color_string = 'bg-yellow-300'
      else color_string = 'bg-red-300'
    }
    return base_string + color_string
  }

  const htmlToImageConvert = useCallback(() => {
    if (elementRef.current === null) {
      return
    }
    toPng(elementRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a')
        const fileName = 'VerticalProfile-' + Date.now() + '.png'
        link.download = fileName
        link.href = dataUrl
        link.click()
        errorStatus.addMessage(
          'PNG exported: ' + fileName,
          400,
          'success',
          false,
          ErrorTimeouts.VERTICAL_PROFILE
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [elementRef])

  const markerIcon = new L.Icon({
    iconUrl: markerImg,
    iconSize: [24, 36],
    iconAnchor: [12, 12],
    popupAnchor: [0, -12],
  })

  const downloadCSVFile = async (url : string) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `raw_csv_file.csv`)
    document.body.appendChild(link)
    link.click()
  }

  const map = useMapEvents({
    popupclose: () => {
      setModalActive(false)
    },
  })

  function getColumns(stationData: VerticalProfileInterface, addExtraCol :boolean): Array<string> {
    let columns = new Set<string>()
    for(let key of Object.keys(stationData)){
      let nestedData = stationData[key] as { [key: string]: any }
       for(let params of Object.keys(nestedData))
        columns.add(params);
    }
    var flteredArray = Array.from(columns).filter(key => key !== "wind_speed" && key !== "wind_direction")
    var addedKeys = ["wind_speed", "wind_direction"].concat(flteredArray.sort())
    if (addExtraCol) return ["HT"].concat(addedKeys)
    return addedKeys
  }

  function getHeights(stationData: VerticalProfileInterface): Array<string> {
    let heights = []
    for(let key of Object.keys(stationData)) heights.push(key)
    return heights.sort((a,b) => Number(b) - Number(a))
  }

  function formatString(metaKeys: string): string {
    return metaKeys.split("_").join(" ")
  }

  const keyMap : Record<string, string> = {
    "dew_point_temperature" : "temp",
    "temperature" : "temp",
    "wind_speed" : "wind",
    "meters" : "m",
    "feets" : "ft"
  }

  return (
    <>
      {showVerticalProfile &&
        verticalProfile.map((station, index) => (
          <Marker key={index} position={[station.geometry.coordinates[1], station.geometry.coordinates[0]]} icon={markerIcon} title={'Vertical Profile'}>
            <Popup autoClose={false} className="vertical-profile-popup">
              <div className="flex justify-between w-full mb-2">
                <div className="p-1 text-base font-semibold ">
                  Vertical Profile
                </div>
                <div className='flex gap-2'>
                  {
                    station.properties.link_csv &&
                    <div className="p-1 text-sm w-max cursor-pointer rounded bg-gray-300 block hover:bg-gray-500 inline-block font-semibold ">
                      <button type="button" onClick={async (event) => { await downloadCSVFile(station.properties.link_csv); }}>
                        Download CSV
                        <svg
                          className="w-4 h-4 ml-1 text-gray-800 dark:text-white inline-block"
                          aria-hidden="true"
                          xmlns="
                                  http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                          />
                        </svg>
                      </button>
                    </div>
                  }
                  <div className="p-1 text-sm w-max cursor-pointer rounded bg-gray-300 block hover:bg-gray-500 inline-block font-semibold">
                    <button type="button" onClick={htmlToImageConvert} >
                      Export Data
                      <svg
                        className="w-4 h-4 ml-1 text-gray-800 dark:text-white inline-block"
                        aria-hidden="true"
                        xmlns="
                              http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className='bg-white' ref={elementRef}>
                <div className="flex flex-wrap gap-4 py-2">
                  {
                    Object.keys(station.properties).map((metaKeys) => (
                      metaKeys !== "data" && metaKeys.split("_")[0] != "link" && metaKeys !== "altitude_units" && metaKeys !== "sensor_type" &&
                      <div className="flex-1 w-full bg-gray-200 p-4 border rounded-md text-left text-black uppercase text-sm text-center whitespace-nowrap">
                        {metaKeys == "station_id" && "Station : "} <b> {station.properties[metaKeys]} </b>
                      </div>
                    ))
                  }

                  {/* This section shows the skewt image and opens a popup on click */}
                  {
                    station.properties.link_image &&
                    <div className="flex-1 w-full cursor-pointer border border-slate-500" onClick={() => {
                      setImagePos([station.geometry.coordinates[1], station.geometry.coordinates[0]])
                      setImageURL(station.properties.link_image)
                      setModalActive(true)
                    }}>
                      <img className="h-20 w-auto" src={skewtThumbnailImage}></img>
                    </div>
                  }

                </div>
                <hr />
                <div className="w-auto relative shadow-md rounded-t-lg py-2">
                  <table className="w-full text-sm text-left text-gray-500 rounded-t-lg">
                    <thead className="bg-gray-100 text-black">
                      <tr>
                        {
                          getColumns(station.properties.data, true).map((columNames, index) => (
                            <th scope="col" className="border border-black px-2 py-2 uppercase text-center">
                              {formatString(columNames)}
                            </th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getHeights(station.properties.data).map((heightKey, index) => (
                          <tr className="border border-black text-black">
                            <th scope="row" className="bg-gray-100 px-6 py-2 font-medium text-sm border border-black whitespace-nowrap">
                              {Math.round(Number(heightKey))} {keyMap[station.properties.altitude_units]}
                            </th>
                            {
                              getColumns(station.properties.data, false).map((param) => (
                                <td className={color_maping(keyMap[param] || "default", station.properties.data[heightKey][param]?.value)}>
                                  {
                                    station.properties.data[heightKey][param]?.value === undefined ||
                                      isNaN(station.properties.data[heightKey][param]?.value) ?
                                      "-" : `${Math.round(Number(station.properties.data[heightKey][param]?.value))} ${station.properties.data[heightKey][param]?.units}`
                                  }
                                </td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </Popup>
          </Marker>
        )
        )
      }
      {
        modalActive &&
        <Popup className="popup-skewt-image" position={imagePos} autoClose={false} >
          <img src={imageURL} alt="skewt diagram" className="skewt-image" />
        </Popup>
      }
    </>
  )
}
export default VerticalProfile