import { Polygon, Popup } from "react-leaflet"
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../store";

const getPolygonColor = (currentHazard: string) => {
  return currentHazard === 'SFC_WND' ? '#CC6600'
  : currentHazard === 'LLWS' ? '#993333'
  : currentHazard === 'TURB-HI' ? '#FF6600'
  : currentHazard === 'TURB-LO' ? '#CC6600'
  : currentHazard === 'M_FZLVL' ? '#6666CC'
  : currentHazard === 'ICE' ? '#0000FF'
  : currentHazard === 'MT_OBSC' ? '#FF00FF'
  : currentHazard === 'IFR' ? '#990099'
  : 'black';
}

const getSigmetColor = (currentHazard: string) => {
  let polyColor = 'black'
  switch (currentHazard) {
    case "CONVECTIVE":
      polyColor = "red";
      break;
    case "TURB":
      polyColor = "yellow";
      break;
    case "ICE":
      polyColor = "blue";
      break;
    case "IFR":
      polyColor = "LightGreen";
      break;
    case "MTN OBSCN":
      polyColor = "Green";
      break;
    default:
      break;
  }

  return polyColor
}

export const AirmetPolygon = ({feature, darkMode, tableView, popupContent, networkData}) => {
    const [inPolygons, setInPolygons] = useState()
    const currentLoctation = useSelector((state: RootState) => state.user.defaultLocation)
    const [popupLatLng, setPopupLatLng] = useState<[number, number]>([currentLoctation.latitude, currentLoctation.longitude]);
    const [showPopup, setShowPopup] = useState(false);
    const polyRef = useRef<Polygon | null>(null)
    const popupRef = useRef(null)
    const hazard: string = feature?.color;
    const airSigmentType = feature.properties.airSigmetType

    const isPointInPolygon = (point, polygon) => {
    const x = point.lng,
        y = point.lat // Correcting the order here to [longitude, latitude]
    let inside = false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xj = polygon[j][0],
        yj = polygon[j][1] // x is longitude, y is latitude
        const xi = polygon[i][0],
        yi = polygon[i][1]

        const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
        if (intersect) {
        inside = !inside
        }
    }
    return inside
    }

    const isPointInPoly = (feature, pt: LatLng) => {
    const poly = feature.coordinates ? feature.coordinates[0] : []
    const y = pt.lat,
        x = pt.lng

    let inside = false
    for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
        const xi = poly[i][1]
        const yi = poly[i][0]
        const xj = poly[j][1]
        const yj = poly[j][0]
        const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi

        if (intersect) inside = !inside
    }
    return inside
    }
    
    useEffect(() => {
      if (showPopup && polyRef.current) {
        polyRef.current.openPopup();
      }
    }, [showPopup]);
  
    // Handle default click
    const handleDefaultClick = (e) => {
      const { latlng } = e;
      setPopupLatLng(latlng)
      handlePolygonPopupClick(latlng, networkData);
    };

    const handlePolygonPopupClick = (properties, feature) => {
        // feature = poly
        const poly = feature //feature.map((p) => {

        const ret = []
        // setPopupLatLng(properties)
        
        networkData.forEach((element) => {
                // test the point for each polygon

          if (element !== undefined && element.properties.severity != 0) {
              const pointInPoly = isPointInPoly(element, properties)

              if (pointInPoly === true) {
              // if point is in polygon, add the AIRMET data to the popup
              ret.push(element)
              }
          }
        })
        setInPolygons(ret)
        setShowPopup(false)
        // Only show popup if ret contains elements
        if (ret.length > 0) {
          setTimeout(() => setShowPopup(true), 0);
        }
    }
    //filter out sigmets that have severity == 0. AWC pushed this new update where a sigmet is issued even if there is no hazard, 
    // just to say that there is no hazard. This needs to be filtered out.
    if (feature !== undefined && feature !== null && feature.properties.severity != 0) {
        return (
            <Polygon
            key={uuidv4()}
            ref={polyRef}
            color={airSigmentType === 'SIGMET' ? getSigmetColor(hazard) : getPolygonColor(hazard)}
            fill={true}
            fillOpacity={0.3}
            weight={Number(3)}
            dashArray={"3"}
            positions={[feature?.coordinates]}
            eventHandlers={{
                click: (e) => {
                  handleDefaultClick(e)
                // handlePolygonPopupClick(e.latlng, networkData)
                },
            }}
            >
                {/* <Popup className="airmet-popup">
                    {tableView && (
                        <AIRMETPopup
                        features={inPolygons}
                        icaoId={feature.properties.icaoId}>
                        </AIRMETPopup>
                    )}
                </Popup> */}
{tableView && inPolygons && inPolygons.length > 0 && (
    <Popup key={uuidv4()} className="airmet-popup" ref={popupRef}>
        <AIRMETPopup key={uuidv4()} features={inPolygons} icaoId={feature.properties.icaoId}/>
    </Popup>
)}
            </Polygon>
        )
    } else {
        return null
    }
}

const AIRMETPopup = ({features, icaoId}) => {

    const [selectedAIRMET, setSelectedAIRMET] = useState<number | null>(null)
    const elements = []
    const [content, setContent] = useState(null)
  
    const toggleHazard = (i) => {
      if (selectedAIRMET === i) {
        setSelectedAIRMET(null)
      } else {
        setSelectedAIRMET(i)
      }
    }
  
    const items = features
  
    for (const item in items) {
      const currentItem = { ...items[item] }
      const icaoId = currentItem.properties.icaoId
      const airSigmetType = currentItem.properties.airSigmetType // save the data type
      const data = currentItem.properties.hazard // save the data type
      const rawAirSigmet = currentItem.properties.rawAirSigmet // save the raw airsigmet
  
      // convert the object to an array of key-value pairs
      const properties = Object.keys(currentItem.properties).map((key) => [
        key,
        currentItem.properties[key],
      ])
      properties.sort((a, b) => a[0].localeCompare(b[0])) //
      elements.push(
        <>
          <div className="flex flex-col w-full text-sm">
            <div
              className="flex w-full text-sm cursor-pointer"
              data-idx={item}
              onClick={() => toggleHazard(item)}
            >
              <div
                className="w-8 h-4 mb-3 mr-4 text-white text-center"
                style={{ backgroundColor: airSigmetType === 'SIGMET' ? getSigmetColor(currentItem.color) : getPolygonColor(currentItem.color) }}
              >
                {/*'⨁'*/}
              </div>
              <div className="mr-1">{currentItem.properties.airSigmetType}</div>
              <div className="mr-1">{currentItem.color}</div>
              <div className="mr-1">{icaoId}</div>
            </div>
            {selectedAIRMET === null ? (
              <></>
            ) : selectedAIRMET === item ? (
              <AirmetTable
                currentItem={currentItem}
                icaoId={icaoId}
                hazard={data}
                data={data}
                rawAirSigmet={rawAirSigmet}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      )
    }
  
    return <>{elements}</>
  }

  const AirmetTable = (props) => {
    const { currentItem, icaoId, hazard, data, rawAirSigmet } = props
    // convert the object to an array of key-value pairs
    const airSigmentType = currentItem.properties.airSigmetType
    const properties = Object.keys(currentItem.properties)
      .map((key) => [key, currentItem.properties[key]])
      .sort((a, b) => a[0].localeCompare(b[0]))
    return (
      <>
        <div>
          <div className="flex flex-col ">
            {/* <div className="flex w-full text-sm py-4">
              <div className="mr-1 basis-36 grow-0 shrink-0">airSigmentType</div>
              <div className="mr-1">{airSigmentType}</div>
            </div>
            <div className="flex w-full text-sm py-4">
              <div className="mr-1 basis-36 grow-0 shrink-0">icaoId</div>
              <div className="mr-1">{icaoId}</div>
            </div> */}
            {properties.map(([key, value], index) => {
              // Skip rendering the specified keys
              // if (
              //   key === 'airSigmetType' ||
              //   key === 'icaoId' ||
              //   key === 'rawAirSigmet' ||
              //   key === 'data'
              // ) {
              //   return null
              // }
              return (
                <div className="flex w-full text-sm py-4" key={index}>
                  <div className="mr-1 basis-36 grow-0 shrink-0">{key}</div>
                  <div className="mr-1">{value}</div>
                </div>
              )
            })}
          </div>
          {/* <div className="flex w-full text-sm py-4" title={props.rawAirSigmet}>
            <div className="mr-1 basis-36 grow-0 shrink-0">rawAirSigmet</div>
            <div className="mr-1">{props.rawAirSigmet}</div>
          </div> */}
        </div>
      </>
    )
  }
  