import { TWS_API_URL } from '../environment/apis.config'
import { getAccessToken } from '../utils/auth.util'
// import { WMSURL } from '../environment/apis.config'
export type LayerSpecs = {
  id: string
  name: string
  crs: string
}

export const layerSpecs: LayerSpecs[] = [
  {
    id: 'Ceiling (US)',
    name: 'Ceiling_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Visibility',
    // name: 'Temperature_V360',
    name: 'Visibility_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Winds Aloft',
    name: 'Wind_80_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Surface Winds',
    name: 'Wind_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Temperature',
    name: 'Temperature_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Wind Gust',
    name: 'Wind_Gust_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Canada Radar',
    name: 'MRMS-Composite_Reflectivity',
    crs: 'EPSG:3857',
  },
  {
    id: 'Temperature (CA)',
    name: 'Temperature_V360',
    crs: 'EPSG:900913',
  },
  {
    id: 'Ceiling (CA)',
    name: 'LAMP-Ceiling',
    crs: 'EPSG:900913',
  },
  {
    id: 'Visibility (CA)',
    name: 'LAMP-Visibility',
    crs: 'EPSG:900913',
  },
  {
    id: 'Wind Gust (CA)',
    name: 'Wind_Gust_V360',
    crs: 'EPSG:900913',
  },
  {
    id: '80M Wind (CA)',
    name: 'Wind_80_V360',
    crs: 'EPSG:3857',
  },
  {
    id: 'Surface Winds (CA)',
    name: 'Wind_V360',
    crs: 'EPSG:3857',
  },
  {
    id: 'Temperature (BR)',
    name: 'CW100SP1-Temperature',
    crs: 'EPSG:900913',
  },
  {
    id: 'Ceiling (BR)',
    name: 'MABR-Ceiling',
    crs: 'EPSG:900913',
  },
  {
    id: 'Visibility (BR)',
    name: 'CW100SP1-Visibility',
    crs: 'EPSG:900913',
  },
  {
    id: 'Wind Gust (BR)',
    name: 'CW100SP1-Wind_Gusts',
    crs: 'EPSG:900913',
  },
  {
    id: '80M Wind (BR)',
    name: 'CW100SP1-Wind_80',
    crs: 'EPSG:900913',
  },
  {
    id: 'Surface Winds (BR)',
    name: 'CW100SP1-Wind',
    crs: 'EPSG:900913',
  },
  {
    id: 'Low Cloud (US)',
    name: 'HRRR-LowCloudShaded',
    crs: 'EPSG:900913',
  },
  {
    id: 'Mid Cloud (US)',
    name: 'HRRR-MidCloudShaded',
    crs: 'EPSG:900913',
  },
  {
    id: 'Smoke (US)',
    name: 'HRRR-VertIntSmoke',
    crs: 'EPSG:900913',
  },
  {
    id: '80M Wind (Western Europe)',
    name: 'MMIX-Wind_80',
    crs: 'EPSG:900913',
  },
  {
    id: 'Ceiling (Western Europe)',
    name: 'MMIX-Ceiling',
    crs: 'EPSG:900913',
  },
  {
    id: 'Surface Winds (Western Europe)',
    name: 'MMIX-Wind',
    crs: 'EPSG:900913',
  },
  {
    id: 'Temperature (Western Europe)',
    name: 'MMIX-Temperature',
    crs: 'EPSG:900913',
  },
  {
    id: 'Visibility (Western Europe)',
    name: 'MMIX-Visibility',
    crs: 'EPSG:900913',
  },
  {
    id: 'Wind Gust (Western Europe)',
    name: 'MMIX-Wind_Gusts',
    crs: 'EPSG:900913',
  },
  {
    id: 'Cloud Ceiling (CE)',
    name: 'MMCE1-Ceiling',
    crs: 'EPSG:3857',
  },
  {
    id: 'Visibility (CE)',
    name: 'MMCE1-Visibility',
    crs: 'EPSG:3857',
  },
  {
    id: 'Temperature (CE)',
    name: 'MMCE1-Temperature',
    crs: 'EPSG:3857',
  },
  {
    id: 'Surface Winds (CE)',
    name: 'MMCE1-Wind',
    crs: 'EPSG:3857',
  },
  {
    id: '80m Winds (CE)',
    name: 'MMCE1-Wind_80',
    crs: 'EPSG:3857',
  },
  {
    id: 'Wind Gust (CE)',
    name: 'MMCE1-Wind_Gusts',
    crs: 'EPSG:3857',
  },
  {
    id: 'Cloud Ceiling (Middle East)',
    name: 'MMME1-Ceiling',
    crs: 'EPSG:3857',
  },
  {
    id: 'Visibility (Middle East)',
    name: 'MMME1-Visibility',
    crs: 'EPSG:3857',
  },
  {
    id: 'Temperature (Middle East)',
    name: 'MMME1-Temperature',
    crs: 'EPSG:3857',
  },
  {
    id: 'Surface Winds (Middle East)',
    name: 'MMME1-Wind',
    crs: 'EPSG:3857',
  },
  {
    id: '80m Winds (Middle East)',
    name: 'MMME1-Wind_80',
    crs: 'EPSG:3857',
  },
  {
    id: 'Wind Gust (Middle East)',
    name: 'MMME1-Wind_Gusts',
    crs: 'EPSG:3857',
  }, 
  {
    id: 'WAFS-Icing',
    name: 'WAFS-Icing',
    crs: 'EPSG:900913'
  },
  {
    id: 'WAFS-Turbulence',
    name: 'WAFS-Turbulence',
    crs: 'EPSG:900913'
  }
]

export const getWMSTile = async (layerId: string) => {
  console.log('>>>> layer id : ', layerId)
  const layer = layerSpecs.find((l) => l.id === layerId)

  console.log(layer)
  let url = `${TWS_API_URL}/wms/GetGTile?CRS=${layer?.crs}&layer=${layer?.name}&DIM_FORECAST=PT0S&STYLE=default&TILESIZE=1024&TRANSPARENT=TRUE&FORMAT=image/png`
  if(layerId == "WAFS-Icing" || layerId == "WAFS-Turbulence"){
    url = `${TWS_API_URL}/wms/GetGTile?CRS=${layer?.crs}&layer=${layer?.name}&DIM_FORECAST=PT6H&STYLE=default&TILESIZE=1024&TRANSPARENT=TRUE&FORMAT=image/png`
  }
  const token = await getAccessToken();
  
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) {
      throw new Error(
        'Network response not recieved : Error' + response.statusText
      )
    }
    const data = await response.json()
    return data
  } catch (error) {
    // workaround for https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
    let message = 'getWMSTile encountered an error'
    if (error instanceof Error) {
      message = error.message
    }
    return message
  }
}
