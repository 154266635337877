import {
  AddLocationProps,
  DeleteLocationProps,
  // Location,
} from '../interfaces/Location'
import { TWS_API_URL } from '../environment/apis.config'
import { timeouts } from '../services/RequestTimeouts'
import { getAccessToken } from '../utils/auth.util'

export const AddNewLocation = (payload: AddLocationProps) => {
  const controller = new AbortController()

  return new Promise(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Add New Location timed out'))
    }, timeouts.locations)
    const token = await getAccessToken();

    fetch(`${TWS_API_URL}/client/location`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...payload }),
      signal: controller.signal,
    })
      .then(async (res) => {
        clearTimeout(timeoutId)

        if (!res.ok) {
          throw new Error(
            'Request timeout occured: creating new location. Please try again.'
          )
        }
        const resJson = await res.json()
        return resJson
      })
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        console.log(err)
        if (err.name === 'AbortError') {
          reject(
            new Error(
              'Request timeout occured: creating new location. Please try again.'
            )
          )
        } else {
          reject(err)
        }
      })
  })
}

export const DeleteLocation = (payload: DeleteLocationProps) => {
  const controller = new AbortController()

  return new Promise(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('Request Timeout'))
    }, timeouts.locations)
    const token = await getAccessToken();

    fetch(`${TWS_API_URL}/location/${payload}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.json())
        } else {
          reject(res)
        }
      })
      .catch((err) => reject(err))
  })
}
