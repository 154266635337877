import ReactDOM from 'react-dom'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import config from '../auth_config.json'
import { createRoot } from "react-dom/client";

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  audience: config.audience,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
//if (process.env.NODE_ENV !== 'production') console.log = () => {}
// console.log("process.env.NODE_ENV:", process.env.NODE_ENV)
const root = createRoot(document.getElementById('root'));

root.render(
    <Auth0Provider {...providerConfig}>
      <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
      </Provider>
    </Auth0Provider>
)
