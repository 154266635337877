import { CSSProperties, ReactComponentElement, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import Toast, { toastPosition, toastType } from "../Toast/Toast"

type MessageParams = {
    message: string
    messageType: string
    messageCode?: number
    sticky?: boolean
}

type NotifierProps = {
    position: string

}
const Notifier = (props:NotifierProps) => {
    const expanded = useSelector((state: RootState) => state.sideNav.expanded)

    let containerClasses = ''
    let leftOffset = 'left-[53px]'
    let containerStyles: CSSProperties = {zIndex:'99999999'}
    let toasts: React.ReactElement[] = []
    const messageQ = useSelector((state: RootState) => state.messages.messages)
    

    /**
     * create a flex column which contains the items in the queue
     * they will be removed after a time depending on the sticky param
     */
    const getContainerClasses = () => {
        if (expanded === true) {
            if (props.position !== 'top-center' && props.position !== 'bottom-center') {
                leftOffset = 'left-[270px]'
            }
            else {
                leftOffset = 'left-[calc(100% - 270px) + calc(50%)]'
            }
        } else {
            leftOffset = 'left-[53px]'
        }
        if (props.position === 'top-right') {
            containerClasses = 'absolute p-2 flex flex-col justify-start w-1/3 h-fit top-16 right-0'
        } else if (props.position === 'bottom-right') {
            containerClasses = 'absolute p-2 flex flex-col justify-start w-96 h-fit bottom-0 right-0'
        } else if (props.position === 'center-right') {
            containerClasses = 'absolute p-2 flex flex-col justify-start w-96 h-fit top-1/2 -translate-y-1/2 right-0'
        } else if (props.position === 'top-left') {
            containerClasses = `absolute p-2 flex flex-col justify-start w-96 h-fit top-16 ${leftOffset}`
        } else if (props.position === 'bottom-left') {
            containerClasses = `absolute p-2 flex flex-col justify-start w-96 h-fit bottom-0 ${leftOffset}`
        } else if (props.position === 'center-left') {
            containerClasses = `absolute p-2 flex flex-col justify-start w-96 h-fit top-1/2 -translate-y-1/2 ${leftOffset}`
        } else if (props.position === 'top-center') {
            containerClasses = `absolute p-2 flex flex-col justify-start w-96 h-fit top-16 left-1/2`
            containerStyles = {transform: 'translateX(-50%)', ...containerStyles}
        }
        else if (props.position === 'bottom-center') {
            containerClasses = `absolute p-2 flex flex-col justify-start w-96 h-fit bottom-0 left-1/2`
            containerStyles = {transform: 'translateX(-50%)', ...containerStyles}
        }
    }
    
    getContainerClasses()

    useEffect(() => {
        getContainerClasses()
        messageQ.forEach((item,index) => {
            toasts.push(<Toast key={item.identifier} toastMessage={item.message} toastPosition={toastPosition.notifier} toastType={item.messageType} identifier={item.identifier} duration={item.duration}/>)
        })
    }, [messageQ, expanded])
    
    return (
        <div className={containerClasses} style={containerStyles}>
            {/* {toasts} */}
            {messageQ.map((item,index) => {
                // debugger
                const messageType = item.messageType === 'info' ? toastType.info : item.messageType === 'error' ? toastType.error : item.messageType === 'success' ? toastType.success : toastType.warning
                return (
                    <Toast key={item.identifier} toastMessage={item.message} toastPosition={toastPosition.notifier} toastType={messageType} identifier={item.identifier} />
                )
            })}
        </div>
    )
}

export default Notifier