import { TWS_API_URL } from '../../environment/apis.config'
import { getAccessToken } from '../../utils/auth.util';
import { SigmetAPIParser } from '../../utils/SIGMETUtils'
import { timeouts } from '../RequestTimeouts'

export const fetchSIGMETData = async (
  defaultLocation: { latitude: any; longitude: any } | undefined
) => {
  const controller = new AbortController()

  return new Promise(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      controller.abort()
      reject(new Error('SIGMET request timed out. Please try again.'))
    }, timeouts.sigmet)
    const token = await getAccessToken();

    const response = await fetch(
      `${TWS_API_URL}/airsig?product=sigmets`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
      },
    )
    .then(res => {
      clearTimeout(timeoutId)
      if (!res.ok) {
        throw new Error("Failed to fetch SIGMET data")
      }
      return res.json()
    })
    .then(data => {
      resolve(SigmetAPIParser(data))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        reject(new Error('SIGMET request aborted.'))
      } else {
        reject(error)
      }
    })
  })
}

export default fetchSIGMETData
