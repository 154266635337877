import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  pushMessage,
  removeMessage,
} from '../Components/Notifier/StatusQueueSlice'

/**
 * Building an Error Handling Layer in React
 * https://semaphoreci.com/blog/error-handling-layer-react
 *
 * ChatGPT
 * https://chat.openai.com/c/5aaa423e-3ff9-4db6-87dc-4537554ce275
 *
 * YouTube
 * https://www.youtube.com/watch?v=0LpLxEUephc
 *
 */
const useErrorStatus = () => {
  const [statusMessage, setStatusMessage] = useState<string>('')
  const [statusCode, setStatusCode] = useState<number>(-1)
  const [statusType, setStatusType] = useState<string>('info')
  const [sticky, setSticky] = useState<boolean>(false)
  const dispatch = useDispatch()

  const addMessage = (
    message: string,
    code: number,
    statusType: string,
    sticky: boolean,
    toastDuration = 2000,
  ) => {
    setStatusMessage(message)
    setStatusCode(code)
    setStatusType(statusType)
    setSticky(sticky)
    const rand = Math.round(Math.random() * 100000000).toString(16)
    dispatch(
      pushMessage({
        message: message,
        messageCode: code,
        messageType: statusType,
        sticky: sticky,
        identifier: rand,
        duration: toastDuration,
      })
    )

    return rand
  }

  const deleteMessage = (identifier: string) => {
    // addMessage('',-1,'info', sticky)
    dispatch(removeMessage(identifier))
  }

  return {
    statusMessage,
    statusCode,
    statusType,
    sticky,
    addMessage,
    deleteMessage,
  }
}

export default useErrorStatus
