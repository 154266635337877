import { TWS_API_URL } from "../../environment/apis.config";
import { getAccessToken } from "../../utils/auth.util";

export const fetchUserThresholds = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(TWS_API_URL+'/customthreshold', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      if(data["createdAt"]){
        delete data["createdAt"]
      }
      if(data["updatedAt"]){
        delete data["updatedAt"]
      }
      return data;
    } catch (error) {
      throw new Error('Error fetching user thresholds');
    }
  };
  
  export const updateThreshold = async (parameterName:string, updatedThreshold:any) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(TWS_API_URL+'/customthreshold', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ [parameterName]: updatedThreshold[parameterName] }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const updatedData = await response.json();
      return updatedData;
    } catch (error) {
      throw new Error('Error updating user thresholds');
    }
  };
  
  export const deleteThreshold = async (parameter:string) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(TWS_API_URL+'/customthreshold', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ [parameter]: {} }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      throw new Error('Error deleting user threshold');
    }
  };
  
