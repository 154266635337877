import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0User, setTokenGetter, setUser } from "./auth.util";
import { TWS_API_URL } from '../environment/apis.config';
import PricingTable from '../Components/Stripe/PricingTable';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PostRegistration from '../Components/Users/RegisterPage/PostRegistration';

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { 
    isAuthenticated, 
    isLoading, 
    error, 
    user, 
    loginWithRedirect, 
    getAccessTokenSilently, 
    logout
  } = useAuth0();
  const [isUserActive, setIsUserActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const redirectToPayment = async () => {
      const token = await getAccessTokenSilently();
      const url = `${TWS_API_URL}/payment?token=${token}`;
      window.location.href = url;
    };

    if (isLoading) return;

    if (error) {
      // Do nothing
    } else if (!isAuthenticated) {
      const url = window.location.href;
      const inviteMatches = url.match(/invitation=([^&]+)/);
      const orgMatches = url.match(/organization=([^&]+)/);
      // Redirect to the login page if not authenticated
      if (inviteMatches && orgMatches) {
        loginWithRedirect({
          authorizationParams: {
            organization: orgMatches[1],
            invitation: inviteMatches[1],
          },
        });
      } else {
        loginWithRedirect();
      }
    } else if (isAuthenticated && user) {
      // Set auth.util helpers
      setTokenGetter(() => getAccessTokenSilently());
      setUser(user as Auth0User);
      
      const isActive = !!user.isActive;
      if (isAuthenticated && !isUserActive) {

      }
      // if (!isActive) {
        // redirectToPayment();
      // }
      setIsUserActive(isActive);
      if (!isActive) {
        navigate("/pricing");
      }
    }
  }, [isAuthenticated, error, isLoading, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <PostRegistration/>;
  }

  if (isAuthenticated) {
    if (isUserActive) {
      return <>{children}</>;
    }

    return (
      <Routes>
        <Route path="/pricing" element={<PricingTable />} />
      </Routes>
    )
  }

  // Render nothing while redirecting or handling errors
  return null;
};

export default RequireAuth;
